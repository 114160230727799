import { LOCAL_CONSTANT } from "../Const";

//活性か非活性か判定する関数
export const setPermissionFunc = (userType: any, taskId: any, page: string, item: string) => {
  //false 活性、 trueが非活性
  const RFC = 'z003';
  const RR = 'z001';  //リサーチ
  const RM = 'z002';
  const GM = 'z005';
  const OTHER = 'z004'; //その他

  const taskGroup = groupSearchFunc(taskId);

  //権限種別
  //RFC
  if (RFC === userType) {
    if (taskGroup === 'A') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        
      //RR内部
      } else if  (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        //「アップロード」ボタン 非表示
        //表紙
      } else if (page === LOCAL_CONSTANT.NAVI.COVER) {
        if (item === 'surveyType') return false;                                //調査種別
        else if (item === 'existingStoreDesign') return false;                  //既存店図面の面積・進入口が現状と差がないことを確認後、チェック記入
        else if (item === 'sAndBCase') return false;                            //S&Bの場合　車・人共通比根拠シート
        else if (item === 'conflictRuinsCase') return false;                    //競合跡地の場合　競合日販推定表
        else if (item === 'targetContractNotOpened') return false;              //調査対象範囲内に契約済未開店のセブン-イレブンは
        else if (item === 'contractDueDate') return false;                      //契約予定日
        else if (item === 'openDueDate') return false;                          //開店予定日
        //出店条件
      } else if (page === LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS) {
        if (item === 'settingReason') return false;                             //1. 設定の理由について ゾーン設定の理由
        else if (item === 'candidateLocationRuins') return false;               //1. 設定の理由について 候補地が自社・​競合CVS跡地の場合
        else if (item === 'candidateLocationRuinsLongText') return false;       //1. 設定の理由について 候補地が自社・​競合CVS跡地の場合・詳細
        else if (item === 'isStoreNameSelectReason') return false;              //2. 店名選定の理由について 店名選定の理由について
        else if (item === 'removalReason') return false;                        //2. 店名選定の理由について 店名選定の理由について・詳細
        else if (item === 'open24Hours') return false;                          //2. 店名選定の理由について 営業条件 24時間営業 有
        else if (item === 'alcoholLicense') return false;                       //2. 店名選定の理由について 営業条件 酒免許 有
        else if (item === 'tobaccoLicence') return false;                       //2. 店名選定の理由について 営業条件 煙草免許 有
        else if (item === 'areaType') return false;                             //2. 店名選定の理由について 営業条件 地域区分
        else if (item === 'nearestAlreadystoreDistance') return false;          //2. 店名選定の理由について 営業条件 最寄り既設店までの距離
        else if (item === 'environmentType') return false;                      //2. 店名選定の理由について 営業条件 環境区分
        else if (item === 'acquisitionRequirement') return false;               //2. 店名選定の理由について 営業条件 取得要件
        else if (item === 'distanceStandardAlreadystoreCount') return false;    //2. 店名選定の理由について 営業条件 距離基準内の既設店舗数
        else if (item === 'conflictCVS') return false;                          //2. 店名選定の理由について 前職 候補地が競合CVS 元
        else if (item === 'cvsLastNippan') return false;                        //2. 店名選定の理由について 前職 候補地が競合CVS CVS直近日販
        else if (item === 'relocationDistance') return false;                   //2. 店名選定の理由について 前職 セブン-イレブン店 移転
        else if (item === 'ownershipType') return false;                        //2. 店名選定の理由について 所有形態
        else if (item === 'storeType') return false;                            //2. 店名選定の理由について 店舗形態
        else if (item === 'storefrontage') return false;                        //2. 店名選定の理由について 特記事項 店舗間口
        else if (item === 'storeArea') return false;                            //2. 店名選定の理由について 特記事項 店舗面積
        else if (item === 'salsesArea') return false;                           //2. 店名選定の理由について 特記事項 売場
        else if (item === 'openCase') return false;                             //オープンケース（台）
        else if (item === 'nakajimaChilled') return false;                      //中島チルド（台）
        else if (item === 'islandChilled') return false;                        //アイランドチルド（台）
        else if (item === 'gondolaUnits') return false;                         //ゴンドラ：尺数
        else if (item === 'gondolaHeight') return false;                        //ゴンドラ：高さ
        else if (item === 'iceCaseLarge') return false;                          //アイスケース：大
        else if (item === 'iceCaseMedium') return false;                        //アイスケース：中
        else if (item === 'iceCaseSmall') return false;                         //アイスケース：小
        else if (item === 'compactStocker') return false;                       //コンパクト　　　ストッカー（台）
        else if (item === 'lowTempReachIn') return false;                       //低温リーチ　　　イン（枚）
        else if (item === 'walkIn') return false;                               //ウォークイン（枚）
        else if (item === 'conterWidth') return false;                          //カウンター幅（ｍ）
        else if (item === 'averageNippan') return false;                        //S店情報 平均日販
        else if (item === 'achievementAcquisitionStart') return false;          //S店情報 実績取得期間 前
        else if (item === 'achievementAcquisitionEnd') return false;            //S店情報 実績取得期間 後
        else if (item === 'sStoreOpenDate') return false;                       //S店情報 S店開店日
        else if (item === 'sStoreCloseDueDate') return false;                   //S店情報 S店閉店予定日
        else if (item === 'inSalesConstructionDays') return false;              //S店情報 営業中工事日数
        else if (item === 'carCommonRatio') return false;                       //S店情報 車共通比
        else if (item === 'personCommonRatio') return false;                    //S店情報 人共通比
        else if (item === 'sStoreLineDistance') return false;                   //S店情報 S店との直線距離
        else if (item === 'sStoreWayDistance') return false;                    //S店情報 S店との道なり距離
        else if (item === 'sStoreCarCustomerRatio') return false;               //S店車客構成比
        else if (item === 'lastfixedMonth') return false;                       //S店情報 直近確定月
        else if (item === 'lastOneYearNippan') return false;                    //S店情報 直近1年日販(千円) 
        else if (item === 'yearOnYear') return false;                           //S店情報 前年比％
        else if (item === 'yearMonth') return false;                            //直近1年以内に起きた環境変化 年月
        else if (item === 'changeType') return false;                           //直近1年以内に起きた環境変化 変化の種類
        else if (item === 'content') return false;                              //直近1年以内に起きた環境変化 内容
        else if (item === 'storeMoveType') return false;                        //既存店敷地移転区分
        else if (item === 'storeMoveDistance') return false;                    //既存店敷地移転区分
        //商圏
      } else if (page === LOCAL_CONSTANT.NAVI.TRADE_AREA) {
        if (item === 'realitySurveyHouseholdCount') return false;               //商圏内データ 実査世帯数
        else if (item === 'realitySurveyOffice') return false;                  //商圏内データ 実査事業所
        else if (item === 'realitySurveyEmployedWorkers') return false;         //商圏内データ 実査就業者
        //現況写真
      } else if (page === LOCAL_CONSTANT.NAVI.PICTURE) {
        if (item === 'photoAddButton') return false;                            //写真追加ボタン
        else if (item === 'photoEditButton') return false;                      //写真編集ボタン
        else if (item === 'photoDeleteButton') return false;                    //写真削除ボタン
        else if (item === 'viewing_distance') return false;                     //第一進入口の左端から、最後に見える位置までの距離
        //寄付き
      } else if (page === LOCAL_CONSTANT.NAVI.DONATION) {
        if (item === 'drivingLanePositiveFrontage') return false;               //走行車線 正 前面
        else if (item === 'drivingLanePositiveSideWayA') return false;          //走行車線 正 側道A
        else if (item === 'drivingLanePositiveSideWayB') return false;          //走行車線 正 側道B
        else if (item === 'drivingLanePositiveBackstreet') return false;        //走行車線 正 裏道
        else if (item === 'drivingLaneOppositeFrontage') return false;          //走行車線 反 前面
        else if (item === 'drivingLaneOppositeSideWayA') return false;          //走行車線 反 側道A
        else if (item === 'drivingLaneOppositeSideWayB') return false;          //走行車線 反 側道B
        else if (item === 'drivingLaneOppositeBackstreet') return false;        //走行車線 反 裏道
        else if (item === 'medianStripSelect') return false;                    //中央分離帯 有無 前面
        else if (item === 'medianStripSelect2') return false;                   //中央分離帯 有無 側道A
        else if (item === 'medianStripSelect3') return false;                   //中央分離帯 有無 側道B
        else if (item === 'medianStripSelect4') return false;                   //中央分離帯 有無 裏道
        else if (item === 'medianStripHeight') return false;                    //中央分離帯 高さ 前面
        else if (item === 'medianStripHeight2') return false;                   //中央分離帯 高さ 側道A
        else if (item === 'medianStripHeight3') return false;                   //中央分離帯 高さ 側道B
        else if (item === 'medianStripHeight4') return false;                   //中央分離帯 高さ 裏道
        else if (item === 'sidewalkSelect') return false;                       //歩道 有無 前面
        else if (item === 'sidewalkSelect2') return false;                      //歩道 有無 側道A
        else if (item === 'sidewalkSelect3') return false;                      //歩道 有無 側道B
        else if (item === 'sidewalkSelect4') return false;                      //歩道 有無 裏道
        else if (item === 'sidewalkHeight') return false;                       //歩道 高さ 前面
        else if (item === 'sidewalkHeight2') return false;                      //歩道 高さ 側道A
        else if (item === 'sidewalkHeight3') return false;                      //歩道 高さ 側道B
        else if (item === 'sidewalkHeight4') return false;                      //歩道 高さ 裏道
        else if (item === 'roadWidthDoor') return false;                        //車道幅員 ドア 前面
        else if (item === 'roadWidthDoor2') return false;                       //車道幅員 ドア 側道A
        else if (item === 'roadWidthDoor3') return false;                       //車道幅員 ドア 側道B
        else if (item === 'roadWidthDoor4') return false;                       //車道幅員 ドア 裏道
        else if (item === 'roadWidthForward') return false;                     //車道幅員 先 前面
        else if (item === 'roadWidthForward2') return false;                    //車道幅員 先 側道A
        else if (item === 'roadWidthForward3') return false;                   //車道幅員 先 側道B
        else if (item === 'roadWidthForward4') return false;                    //車道幅員 先 裏道
        else if (item === 'roadWidthFrontLocation') return false;               //車道幅員 手前 前面
        else if (item === 'roadWidthFrontLocation2') return false;              //車道幅員 手前 側道A
        else if (item === 'roadWidthFrontLocation3') return false;              //車道幅員 手前 側道B
        else if (item === 'roadWidthFrontLocation4') return false;              //車道幅員 手前 裏道
        else if (item === 'slopeClimb') return false;                           //坂道(長さ1m当たり) 登 前面
        else if (item === 'slopeClimb2') return false;                          //坂道(長さ1m当たり) 登 側道A
        else if (item === 'slopeClimb3') return false;                          //坂道(長さ1m当たり) 登 側道B
        else if (item === 'slopeClimb4') return false;                          //坂道(長さ1m当たり) 登 裏道
        else if (item === 'slopeDescend') return false;                         //坂道(長さ1m当たり) 降 前面
        else if (item === 'slopeDescend2') return false;                        //坂道(長さ1m当たり) 降 側道A
        else if (item === 'slopeDescend3') return false;                        //坂道(長さ1m当たり) 降 側道B
        else if (item === 'slopeDescend4') return false;                        //坂道(長さ1m当たり) 降 裏道
        else if (item === 'trafficSignalEtcForwardSelect') return false;        //信号・踏切 先 有無 前面
        else if (item === 'trafficSignalEtcForwardSelect2') return false;       //信号・踏切 先 有無 側道A
        else if (item === 'trafficSignalEtcForwardSelect3') return false;       //信号・踏切 先 有無 側道B
        else if (item === 'trafficSignalEtcForwardSelect4') return false;       //信号・踏切 先 有無 裏道
        else if (item === 'trafficSignalEtcForwardDistance') return false;      //信号・踏切 先 距離 前面
        else if (item === 'trafficSignalEtcForwardDistance2') return false;     //信号・踏切 先 距離 側道A
        else if (item === 'trafficSignalEtcForwardDistance3') return false;     //信号・踏切 先 距離 側道B
        else if (item === 'trafficSignalEtcForwardDistance4') return false;     //信号・踏切 先 距離 裏道
        else if (item === 'trafficSignalEtcFrontLocationSelect') return false;  //信号・踏切 手前 有無 前面
        else if (item === 'trafficSignalEtcFrontLocationSelect2') return false; //信号・踏切 手前 有無 側道A
        else if (item === 'trafficSignalEtcFrontLocationSelect3') return false; //信号・踏切 手前 有無 側道B
        else if (item === 'trafficSignalEtcFrontLocationSelect4') return false; //信号・踏切 手前 有無 裏道
        else if (item === 'trafficSignalEtcFrontLocationDistance') return false;    //信号・踏切 手前 距離 前面
        else if (item === 'trafficSignalEtcFrontLocationDistance2') return false;   //信号・踏切 手前 距離 側道A
        else if (item === 'trafficSignalEtcFrontLocationDistance3') return false;   //信号・踏切 手前 距離 側道B
        else if (item === 'trafficSignalEtcFrontLocationDistance4') return false;   //信号・踏切 手前 距離 裏道
        else if (item === 'visibilityFactorBuilding') return false;             //視界性 建物 前面
        else if (item === 'visibilityFactorBuilding2') return false;            //視界性 建物 側道A
        else if (item === 'visibilityFactorBuilding3') return false;            //視界性 建物 側道B
        else if (item === 'visibilityFactorBuilding4') return false;            //視界性 建物 裏道
        else if (item === 'visibilityFactorSignPost') return false;             //視界性 サインポール 前面
        else if (item === 'visibilityFactorSignPost2') return false;           //視界性 サインポール 側道A
        else if (item === 'visibilityFactorSignPost3') return false;            //視界性 サインポール 側道B
        else if (item === 'visibilityFactorSignPost4') return false;            //視界性 サインポール 裏道
        else if (item === 'parkingArea') return false;                          //有効駐車場面積 面積 ㎡
        //有効駐車場面積 面積 坪は有効駐車場面積 面積 ㎡が入力された場合に自動計算
        else if (item === 'parkingCapacity') return false;                      //有効駐車場面積 駐車可能台数
        else if (item === 'parkingTangentLineFrontage') return false;            //駐車場接線 前面
        else if (item === 'parkingTangentLineSideWayA') return false;           //駐車場接線 側道A
        else if (item === 'parkingTangentLineSideWayB') return false;           //駐車場接線 側道B
        else if (item === 'parkingTangentLineBackstreet') return false;         //駐車場接線 裏道
        else if (item === 'no1EntranceFrontage') return false;                  //出入口 No.1 進入口 前面
        else if (item === 'no1EntranceSideWayA') return false;                  //出入口 No.1 進入口 側道A
        else if (item === 'no1EntranceSideWayB') return false;                  //出入口 No.1 進入口 側道B
        else if (item === 'no1EntranceBackstreet') return false;                //出入口 No.1 進入口 裏道
        else if (item === 'no1DepthFrontage') return false;                     //出入口 No.1 奥行き 前面
        else if (item === 'no1DepthSideWayA') return false;                     //出入口 No.1 奥行き 側道A
        else if (item === 'no1DepthSideWayB') return false;                     //出入口 No.1 奥行き 側道B
        else if (item === 'no1DepthBackstreet') return false;                    //出入口 No.1 奥行き 裏道
        else if (item === 'no2EntranceFrontage') return false;                  //出入口 No.2 進入口 前面
        else if (item === 'no2EntranceSideWayA') return false;                  //出入口 No.2 進入口 側道A
        else if (item === 'no2EntranceSideWayB') return false;                  //出入口 No.2 進入口 側道B
        else if (item === 'no2EntranceBackstreet') return false;                //出入口 No.2 進入口 裏道
        else if (item === 'no2DepthFrontage') return false;                     //出入口 No.2 奥行き 前面
        else if (item === 'no2DepthSideWayA') return false;                     //出入口 No.2 奥行き 側道A
        else if (item === 'no2DepthSideWayB') return false;                     //出入口 No.2 奥行き 側道B
        else if (item === 'no2DepthBackstreet') return false;                   //出入口 No.2 奥行き 裏道
        else if (item === 'completeScheduleYear') return false;                 //※未開通道路・開発地の場合　完了予定　年
        else if (item === 'completeScheduleMonth') return false;                //※未開通道路・開発地の場合　完了予定　月
        //通行量
      } else if (page === LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME) {
        if (item === 'car_another_survey') return false;                            //車通行量 別途調査必要時（該当を選択）
        else if (item === 'add_car') return false;                                  //車通行量 追加ボタン
        else if (item === 'trafficCarEditButton') return false;                     //車（朝、昼、夜、休日） 編集ボタン
        else if (item === 'trafficCarDeleteButton') return false;                   //車（朝、昼、夜、休日） 削除ボタン
        else if (item === 'people_another_survey') return false;                    //人通行量 別途調査必要時（該当を選択）
        else if (item === 'cut_survey') return false;                               //人通行量 5m外通行量調査
        else if (item === 'add_people') return false;                               //人通行量 追加ボタン
        else if (item === 'trafficPeopleEditButton') return false;                  //人（5m内、5m外）（朝、昼、朝特定、昼特定、夕特定、夜、休日） 編集ボタン
        else if (item === 'trafficPeopleDeleteButton') return false;                //人（5m内、5m外）（朝、昼、朝特定、昼特定、夕特定、夜、休日） 削除ボタン
        else if (item === 'template_dl_button') return false;                       //5m外算入根拠　テンプレートDLボタン
        else if (item === 'excel_dl_button') return false;                          //5m外算入根拠　エクセルDLボタン
        else if (item === 'uploadButton') return false;                             //5m外算入根拠　エクセルULボタン
        else if (item === 'traffic5mcut') return false;                             //5m外歩行者TR算入確認　店舗入口ドア予定位置前に～
        //5m外歩行者TR算入確認　5ｍ外算入範囲から～
        //5m外歩行者TR算入確認　店舗入口ドア予定位置まで～
        //5m外歩行者TR算入確認　道路の反対側をカウントする～
        else if (item === 'others_facility') return false;                          //その他 3行目 施設
        //その他 4行目 施設
        else if (item === 'others_name') return false;                              //その他 駅出入口 名称
        //その他 大学短大 名称
        //その他 3行目 名称
        //その他 4行目 名称
        else if (item === 'others_distance') return false;                          //その他 駅出入口 距離（ｋｍ）
        //その他 大学短大 距離（ｋｍ）
        //その他 3行目 距離（ｋｍ）
        //その他 4行目 距離（ｋｍ）
        else if (item === 'others_value') return false;                           //その他 駅出入口 駅：乗降客数　他：比率
        //その他 大学短大 駅：乗降客数　他：比率
        //その他 3行目 駅：乗降客数　他：比率
        //その他 4行目 駅：乗降客数　他：比率
        //競合
      } else if (page === LOCAL_CONSTANT.NAVI.CONFLICT) {
        if (item === 'CVS_MASTER_DATA') return false;                               //「競合店設定」ボタン
        else if (item === 'CONFLICT_SM_SETTING') return false;                      //「競合SM設定」ボタン
        //残りの自店の項目非活性
        else if (item === 'own_edit_button') return false;                          //自店 編集ボタン
        //競合店　不備コメントボタン 非活性
        else if (item === 'conflict_edit_button') return false;                     //競合店 編集ボタン
        //残りの競合店の項目は編集ボタン活性、非活性を対応
        //商圏カット
      } else if (page === 'TRADE_AREA_CUT') {
        if (item === 'households') return false;                                    //削除商圏 世帯数
        else if (item === 'employees') return false;                                //削除商圏 従業員数
        else if (item === 'CVS_MASTER_DATA') return false;                          //「競合店設定」ボタン
        else if (item === 'CONFLICT_SM_SETTING') return false;                      //「競合SM設定」ボタン
        else if (item === 'template_dl_button') return false;                       //「テンプレートDL」ボタン
        else if (item === 'excel_dl_button') return false;                          //「エクセルDL」ボタン
        else if (item === 'excel_ul_button') return false;                          //「エクセルUL」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        if (item === 'add_button') return false;                                    //「追加」ボタン
        //「既存店・交差点確認地図」ボタン 活性
        else if (item === LOCAL_CONSTANT.LABEL.ROUTE_CHANGE) return false;          //「ルート変更」ボタン
        else if (item === LOCAL_CONSTANT.LABEL.CHANGE) return false;                //「変更」ボタン
        else if (item === LOCAL_CONSTANT.LABEL.DELETE) return false;                //「削除」ボタン
        else if (item === 'store_position') return false;                           //既存店位置
        else if (item === 'common_road') return false;                              //候補店共通道路
        else if (item === 'plan_median_strip') return false;                        //候補店中央分離帯高さ
        else if (item === 'store_median_strip') return false;                       //既存店中央分離帯高さ
        else if (item === 'straight_count') return false;                           //直進回数
        else if (item === 'turn_count') return false;                               //右左折回数
        else if (item === 'railroad_crossing') return false;                        //線路横断
        else if (item === 'river_width') return false;                              //河川幅
        else if (item === 'crossing_road_count') return false;                      //横断車線数合計
        else if (item === 'conflict_sej') return false;                             //経路競合　セブンイレブン
        else if (item === 'conflict_main_cvs') return false;                        //経路競合　LW/FM/セイコー
        else if (item === 'conflict_other_cvs') return false;                       //経路競合　その他
        //周辺既存店店番号 非活性
        //交差点　不備コメント 非活性
        //交差点　編集ボタン 非活性
        else if (item === 'edit_button') return false;
        //交差点　削除ボタン 非活性
        else if (item === 'delete_button') return false;
        //影響度判定部分の表示      未
        //「影響度判定用データ出力」ボタン 非表示
        //「アップロード」ボタン 非表示
      //地図
      } else if  (page === LOCAL_CONSTANT.NAVI.MAP) {
        if (item === 'templateDlButton') return false;                              //テンプレートDLボタン
        else if (item === 'excelDlButton') return false;                            //エクセルDLボタン
        else if (item === 'uploadButton') return false;                             //アップロードボタン
        else if (item === 'downloadPdfButton') return false;                        //PdfDLボタン
        else if (item === 'deleteButton') return false;                             //削除ボタン
      //図面
      } else if (page === LOCAL_CONSTANT.NAVI.DRAWING) {
        if (item === 'uploadButton') return false;                                  //「アップロード」ボタン
        else if (item === 'deleteButton') return false;                             //削除ボタン
        else if (item === 'isGreenGem') return false;                               //縁石
        else if (item === 'isGrave') return false;                                  //墓・墓標・祠
        else if (item === 'isMedianStrip') return false;                            //中央分離帯
        else if (item === 'isBusStopBlackout') return false;                        //バス停・電停
        else if (item === 'isInGreenGem') return false;                             //内縁石
        else if (item === 'isOtherTenantParking') return false;                     //他のテナント駐車場
        else if (item === 'isExpresswayEtcPole') return false;                      //高速道路等の支柱
        else if (item === 'isBusLaneEtc') return false;                             //バスレーン・自転車道路
        else if (item === 'isElectricityPole') return false;                        //電柱
        else if (item === 'isSidewalk') return false;                               //歩道
        else if (item === 'isOneWayStreet') return false;                           //一方通行
        else if (item === 'isTramTrack') return false;                              //路面電車軌道
        else if (item === 'isElectricityPoleBranchLine') return false;              //電柱の支線
        else if (item === 'isCrosswalk') return false;                              //横断歩道
        else if (item === 'isNoEntryRoad') return false;                            //車両進入禁止道路
        else if (item === 'isParkingMeter') return false;                           //パーキングメーター
        else if (item === 'isHighVoltageTowers') return false;                      //高圧線鉄塔
        else if (item === 'isGuardrail') return false;                              //ガードレール
        else if (item === 'isWaterwayEtc') return false;                            //水路・側溝・河川内縁石
        else if (item === 'isOthersPlace') return false;                            //他人所有地・官地
        else if (item === 'isHydrantEtc') return false;                             //消火栓・消化灯
        else if (item === 'isStreetTreesEtc') return false;                         //街路樹・植木
        else if (item === 'isRailroadCrossing') return false;                       //鉄道踏切
        else if (item === 'isOldRoad') return false;                                //取り残された旧道
        else if (item === 'isBillboardTowerEtc') return false;                      //広告塔・看板
        else if (item === 'isStreetLightsEtc') return false;                        //街灯・カーブミラー
        else if (item === 'isBridge') return false;                                 //橋
        else if (item === 'isUnpavedRoad') return false;                            //未舗装道路
        else if (item === 'isGarbageDump') return false;                            //ゴミ捨て場
        else if (item === 'isRoadSigns') return false;                              //道路標識
        else if (item === 'isOverpass') return false;                               //陸橋
        else if (item === 'isUnmaintainedEtc') return false;                        //未整備・工事中道路
        else if (item === 'isOther') return false;                                  //その他 来店・進入の障害物
        else if (item === 'otherLongText') return false;                            //その他 来店・進入の障害物 ロングテキスト
        else if (item === 'hand_drawn_layout') return false;                       //手書き配置図 詳細 計算式、詳細 小計
      }
    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {
      if(page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST){
        if(item === 'menu') return false;                                          //予測集計表サイドメニュー　活性
      }
    } else if (taskGroup === 'E') {
      if(page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST){
        if(item === 'menu') return false;                                          //予測集計表サイドメニュー　活性
      }
    }
    //リサーチ
  } else if (RR === userType) {
    if (taskGroup === 'A') {
      if(page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST){
        if(item === 'menu') return false;                                          //予測集計表サイドメニュー　活性
      }
    } else if (taskGroup === 'B') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'tempUploadButton') return false;                               // 「【仮出力】売上予測集計表アップロード」ボタン 非表示
        else if (item === 'uploadButton') return false;                             // [売上予測集計表アップロード」ボタン 非表示
        else if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
        //RR内部用
      } else if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;                              //「アップロード」ボタン
        //表紙
      } else if (page === LOCAL_CONSTANT.NAVI.COVER) {
        //調査種別 非活性
        if (item === 'existingStoreDesign') return false;                       //既存店図面の面積・進入口が現状と差がないことを確認後、チェック記入
        else if (item === 'sAndBCase') return false;                            //S&Bの場合　車・人共通比根拠シート
        else if (item === 'conflictRuinsCase') return false;                    //競合跡地の場合　競合日販推定表
        //else if (item === 'targetContractNotOpened') return false;              //調査対象範囲内に契約済未開店のセブン-イレブンは
        //else if (item === 'contractDueDate') return false;                      //契約予定日
        //else if (item === 'openDueDate') return false;                          //開店予定日
        //出店条件
      } else if (page === LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS) {
        /* if (item === 'settingReason') return false;                             //1. 設定の理由について ゾーン設定の理由
        else if (item === 'candidateLocationRuins') return false;               //1. 設定の理由について 候補地が自社・​競合CVS跡地の場合
        else if (item === 'candidateLocationRuinsLongText') return false;       //1. 設定の理由について 候補地が自社・​競合CVS跡地の場合・詳細
        else if (item === 'isStoreNameSelectReason') return false;              //2. 店名選定の理由について 店名選定の理由について
        else if (item === 'removalReason') return false;                        //2. 店名選定の理由について 店名選定の理由について・詳細
        else if (item === 'open24Hours') return false;                          //2. 店名選定の理由について 営業条件 24時間営業 有
        else if (item === 'alcoholLicense') return false;                       //2. 店名選定の理由について 営業条件 酒免許 有
        else if (item === 'tobaccoLicence') return false;                       //2. 店名選定の理由について 営業条件 煙草免許 有
        else if (item === 'areaType') return false;                             //2. 店名選定の理由について 営業条件 地域区分
        else if (item === 'nearestAlreadystoreDistance') return false;          //2. 店名選定の理由について 営業条件 最寄り既設店までの距離
        else if (item === 'environmentType') return false;                      //2. 店名選定の理由について 営業条件 環境区分
        else if (item === 'acquisitionRequirement') return false;               //2. 店名選定の理由について 営業条件 取得要件
        else if (item === 'distanceStandardAlreadystoreCount') return false;    //2. 店名選定の理由について 営業条件 距離基準内の既設店舗数
        else if (item === 'conflictCVS') return false;                          //2. 店名選定の理由について 前職 候補地が競合CVS 元
        else if (item === 'cvsLastNippan') return false;                        //2. 店名選定の理由について 前職 候補地が競合CVS CVS直近日販
        else if (item === 'relocationDistance') return false;                   //2. 店名選定の理由について 前職 セブン-イレブン店 移転
        else if (item === 'ownershipType') return false;                        //2. 店名選定の理由について 所有形態
        else if (item === 'storeType') return false;                            //2. 店名選定の理由について 店舗形態
        else if (item === 'storefrontage') return false;                        //2. 店名選定の理由について 特記事項 店舗間口
        else if (item === 'storeArea') return false;                            //2. 店名選定の理由について 特記事項 店舗面積
        else if (item === 'salsesArea') return false;                           //2. 店名選定の理由について 特記事項 売場
        else if (item === 'openCase') return false;                             //オープンケース（台）
        else if (item === 'nakajimaChilled') return false;                      //中島チルド（台）
        else if (item === 'islandChilled') return false;                        //アイランドチルド（台）
        else if (item === 'gondolaUnits') return false;                         //ゴンドラ：尺数
        else if (item === 'gondolaHeight') return false;                        //ゴンドラ：高さ
        else if (item === 'iceCaseLarge') return false;                         //アイスケース：大
        else if (item === 'iceCaseMedium') return false;                        //アイスケース：中
        else if (item === 'iceCaseSmall') return false;                         //アイスケース：小
        else if (item === 'compactStocker') return false;                       //コンパクト　　　ストッカー（台）
        else if (item === 'lowTempReachIn') return false;                       //低温リーチ　　　イン（枚）
        else if (item === 'walkIn') return false;                               //ウォークイン（枚）
        else if (item === 'conterWidth') return false;                          //カウンター幅（ｍ）
        else if (item === 'averageNippan') return false;                        //S店情報 平均日販
        else if (item === 'achievementAcquisitionStart') return false;          //S店情報 実績取得期間 前
        else if (item === 'achievementAcquisitionEnd') return false;            //S店情報 実績取得期間 後
        else if (item === 'sStoreOpenDate') return false;                       //S店情報 S店開店日
        else if (item === 'sStoreCloseDueDate') return false;                   //S店情報 S店閉店予定日
        else if (item === 'inSalesConstructionDays') return false;              //S店情報 営業中工事日数
        else if (item === 'carCommonRatio') return false;                       //S店情報 車共通比
        else if (item === 'personCommonRatio') return false;                    //S店情報 人共通比
        else if (item === 'sStoreLineDistance') return false;                   //S店情報 S店との直線距離
        else if (item === 'sStoreWayDistance') return false;                    //S店情報 S店との道なり距離
        else if (item === 'sStoreCarCustomerRatio') return false;               //S店車客構成比
        else if (item === 'lastfixedMonth') return false;                       //S店情報 直近確定月
        else if (item === 'lastOneYearNippan') return false;                    //S店情報 直近1年日販(千円) 
        else if (item === 'yearOnYear') return false;                           //S店情報 前年比％
        else if (item === 'yearMonth') return false;                            //直近1年以内に起きた環境変化 年月
        else if (item === 'changeType') return false;                           //直近1年以内に起きた環境変化 変化の種類
        else if (item === 'content') return false;                              //直近1年以内に起きた環境変化 内容
        else if (item === 'storeMoveType') return false;                        //既存店敷地移転区分
        else if (item === 'storeMoveDistance') return false;                    //既存店敷地移転区分 */
        //商圏
      } else if (page === LOCAL_CONSTANT.NAVI.TRADE_AREA) {
        //if (item === 'realitySurveyHouseholdCount') return false;               //商圏内データ 実査世帯数
        //else if (item === 'realitySurveyOffice') return false;                  //商圏内データ 実査事業所
        //else if (item === 'realitySurveyEmployedWorkers') return false;         //商圏内データ 実査就業者
        //現況写真
      } else if (page === LOCAL_CONSTANT.NAVI.PICTURE) {
        if (item === 'photoAddButton') return false;                            //写真追加ボタン
        else if (item === 'photoEditButton') return false;                      //写真編集ボタン
        else if (item === 'photoDeleteButton') return false;                    //写真削除ボタン
        //else if (item === 'viewing_distance') return false;                     //第一進入口の左端から、最後に見える位置までの距離
        //寄付き
      } else if (page === LOCAL_CONSTANT.NAVI.DONATION) {
        /* if (item === 'drivingLanePositiveFrontage') return false;                   //走行車線 正 前面
        else if (item === 'drivingLanePositiveSideWayA') return false;              //走行車線 正 側道A
        else if (item === 'drivingLanePositiveSideWayB') return false;              //走行車線 正 側道B
        else if (item === 'drivingLanePositiveBackstreet') return false;            //走行車線 正 裏道
        else if (item === 'drivingLaneOppositeFrontage') return false;              //走行車線 反 前面
        else if (item === 'drivingLaneOppositeSideWayA') return false;              //走行車線 反 側道A
        else if (item === 'drivingLaneOppositeSideWayB') return false;              //走行車線 反 側道B
        else if (item === 'drivingLaneOppositeBackstreet') return false;            //走行車線 反 裏道
        else if (item === 'medianStripSelect') return false;                        //中央分離帯 有無 前面
        else if (item === 'medianStripSelect2') return false;                       //中央分離帯 有無 側道A
        else if (item === 'medianStripSelect3') return false;                       //中央分離帯 有無 側道B
        else if (item === 'medianStripSelect4') return false;                       //中央分離帯 有無 裏道
        else if (item === 'medianStripHeight') return false;                        //中央分離帯 高さ 前面
        else if (item === 'medianStripHeight2') return false;                       //中央分離帯 高さ 側道A
        else if (item === 'medianStripHeight3') return false;                       //中央分離帯 高さ 側道B
        else if (item === 'medianStripHeight4') return false;                       //中央分離帯 高さ 裏道
        else if (item === 'sidewalkSelect') return false;                           //歩道 有無 前面
        else if (item === 'sidewalkSelect2') return false;                          //歩道 有無 側道A
        else if (item === 'sidewalkSelect3') return false;                          //歩道 有無 側道B
        else if (item === 'sidewalkSelect4') return false;                          //歩道 有無 裏道
        else if (item === 'sidewalkHeight') return false;                           //歩道 高さ 前面
        else if (item === 'sidewalkHeight2') return false;                          //歩道 高さ 側道A
        else if (item === 'sidewalkHeight3') return false;                          //歩道 高さ 側道B
        else if (item === 'sidewalkHeight4') return false;                          //歩道 高さ 裏道
        else if (item === 'roadWidthDoor') return false;                            //車道幅員 ドア 前面
        else if (item === 'roadWidthDoor2') return false;                           //車道幅員 ドア 側道A
        else if (item === 'roadWidthDoor3') return false;                           //車道幅員 ドア 側道B
        else if (item === 'roadWidthDoor4') return false;                           //車道幅員 ドア 裏道
        else if (item === 'roadWidthForward') return false;                         //車道幅員 先 前面
        else if (item === 'roadWidthForward2') return false;                        //車道幅員 先 側道A
        else if (item === 'roadWidthForward3') return false;                       //車道幅員 先 側道B
        else if (item === 'roadWidthForward4') return false;                        //車道幅員 先 裏道
        else if (item === 'roadWidthFrontLocation') return false;                   //車道幅員 手前 前面
        else if (item === 'roadWidthFrontLocation2') return false;                  //車道幅員 手前 側道A
        else if (item === 'roadWidthFrontLocation3') return false;                  //車道幅員 手前 側道B
        else if (item === 'roadWidthFrontLocation4') return false;                  //車道幅員 手前 裏道
        else if (item === 'slopeClimb') return false;                               //坂道(長さ1m当たり) 登 前面
        else if (item === 'slopeClimb2') return false;                              //坂道(長さ1m当たり) 登 側道A
        else if (item === 'slopeClimb3') return false;                              //坂道(長さ1m当たり) 登 側道B
        else if (item === 'slopeClimb4') return false;                              //坂道(長さ1m当たり) 登 裏道
        else if (item === 'slopeDescend') return false;                             //坂道(長さ1m当たり) 降 前面
        else if (item === 'slopeDescend2') return false;                            //坂道(長さ1m当たり) 降 側道A
        else if (item === 'slopeDescend3') return false;                            //坂道(長さ1m当たり) 降 側道B
        else if (item === 'slopeDescend4') return false;                            //坂道(長さ1m当たり) 降 裏道
        else if (item === 'trafficSignalEtcForwardSelect') return false;            //信号・踏切 先 有無 前面
        else if (item === 'trafficSignalEtcForwardSelect2') return false;           //信号・踏切 先 有無 側道A
        else if (item === 'trafficSignalEtcForwardSelect3') return false;           //信号・踏切 先 有無 側道B
        else if (item === 'trafficSignalEtcForwardSelect4') return false;           //信号・踏切 先 有無 裏道
        else if (item === 'trafficSignalEtcForwardDistance') return false;          //信号・踏切 先 距離 前面
        else if (item === 'trafficSignalEtcForwardDistance2') return false;         //信号・踏切 先 距離 側道A
        else if (item === 'trafficSignalEtcForwardDistance3') return false;         //信号・踏切 先 距離 側道B
        else if (item === 'trafficSignalEtcForwardDistance4') return false;         //信号・踏切 先 距離 裏道
        else if (item === 'trafficSignalEtcFrontLocationSelect') return false;      //信号・踏切 手前 有無 前面
        else if (item === 'trafficSignalEtcFrontLocationSelect2') return false; 	//信号・踏切 手前 有無 側道A
        else if (item === 'trafficSignalEtcFrontLocationSelect3') return false;     //信号・踏切 手前 有無 側道B
        else if (item === 'trafficSignalEtcFrontLocationSelect4') return false;     //信号・踏切 手前 有無 裏道
        else if (item === 'trafficSignalEtcFrontLocationDistance') return false;    //信号・踏切 手前 距離 前面
        else if (item === 'trafficSignalEtcFrontLocationDistance2') return false;   //信号・踏切 手前 距離 側道A
        else if (item === 'trafficSignalEtcFrontLocationDistance3') return false;   //信号・踏切 手前 距離 側道B
        else if (item === 'trafficSignalEtcFrontLocationDistance4') return false;   //信号・踏切 手前 距離 裏道
        else if (item === 'visibilityFactorBuilding') return false;                 //視界性 建物 前面
        else if (item === 'visibilityFactorBuilding2') return false;                //視界性 建物 側道A
        else if (item === 'visibilityFactorBuilding3') return false;                //視界性 建物 側道B
        else if (item === 'visibilityFactorBuilding4') return false;                //視界性 建物 裏道
        else if (item === 'visibilityFactorSignPost') return false;                 //視界性 サインポール 前面
        else if (item === 'visibilityFactorSignPost2') return false;               //視界性 サインポール 側道A
        else if (item === 'visibilityFactorSignPost3') return false;                //視界性 サインポール 側道B
        else if (item === 'visibilityFactorSignPost4') return false;                //視界性 サインポール 裏道
        else if (item === 'parkingArea') return false;                              //有効駐車場面積 面積 ㎡
        //有効駐車場面積 面積 坪は有効駐車場面積 面積 ㎡が入力された場合に自動計算
        else if (item === 'parkingCapacity') return false;                          //有効駐車場面積 駐車可能台数
        else if (item === 'parkingTangentLineFrontage') return false;               //駐車場接線 前面
        else if (item === 'parkingTangentLineSideWayA') return false;               //駐車場接線 側道A
        else if (item === 'parkingTangentLineSideWayB') return false;               //駐車場接線 側道B
        else if (item === 'parkingTangentLineBackstreet') return false;             //駐車場接線 裏道
        else if (item === 'no1EntranceFrontage') return false;                      //出入口 No.1 進入口 前面
        else if (item === 'no1EntranceSideWayA') return false;                      //出入口 No.1 進入口 側道A
        else if (item === 'no1EntranceSideWayB') return false;                      //出入口 No.1 進入口 側道B
        else if (item === 'no1EntranceBackstreet') return false;                    //出入口 No.1 進入口 裏道
        else if (item === 'no1DepthFrontage') return false;                         //出入口 No.1 奥行き 前面
        else if (item === 'no1DepthSideWayA') return false;                         //出入口 No.1 奥行き 側道A
        else if (item === 'no1DepthSideWayB') return false;                         //出入口 No.1 奥行き 側道B
        else if (item === 'no1DepthBackstreet') return false;                       //出入口 No.1 奥行き 裏道
        else if (item === 'no2EntranceFrontage') return false;                      //出入口 No.2 進入口 前面
        else if (item === 'no2EntranceSideWayA') return false;                      //出入口 No.2 進入口 側道A
        else if (item === 'no2EntranceSideWayB') return false;                      //出入口 No.2 進入口 側道B
        else if (item === 'no2EntranceBackstreet') return false;                    //出入口 No.2 進入口 裏道	
        else if (item === 'no2DepthFrontage') return false;                         //出入口 No.2 奥行き 前面
        else if (item === 'no2DepthSideWayA') return false;                         //出入口 No.2 奥行き 側道A
        else if (item === 'no2DepthSideWayB') return false;                         //出入口 No.2 奥行き 側道B
        else if (item === 'no2DepthBackstreet') return false;                       //出入口 No.2 奥行き 裏道 */
        //else if (item === 'completeScheduleYear') return false;                     //※未開通道路・開発地の場合　完了予定　年
        //else if (item === 'completeScheduleMonth') return false;                    //※未開通道路・開発地の場合　完了予定　月
        //通行量
      } else if (page === LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME) {
        if (item === 'car_another_survey') return false;                            //車通行量 別途調査必要時（該当を選択）
        else if (item === 'add_car') return false;                                  //車通行量 追加ボタン
        else if (item === 'trafficCarCommentButton') return false;                  //車（朝、昼、夜、休日） 不備コメントボタン
        else if (item === 'trafficCarEditButton') return false;                     //車（朝、昼、夜、休日） 編集ボタン
        else if (item === 'trafficCarDeleteButton') return false;                   //車（朝、昼、夜、休日） 削除ボタン
        else if (item === 'people_another_survey') return false;                    //人通行量 別途調査必要時（該当を選択）
        else if (item === 'cut_survey') return false;                               //人通行量 5m外通行量調査
        else if (item === 'add_people') return false;                               //人通行量 追加ボタン
        else if (item === 'trafficPeopleCommentButton') return false;               //人（5m内、5m外）（朝、昼、朝特定、昼特定、夕特定、夜、休日） 不備コメントボタン
        else if (item === 'trafficPeopleEditButton') return false;                  //人（5m内、5m外）（朝、昼、朝特定、昼特定、夕特定、夜、休日） 編集ボタン
        else if (item === 'trafficPeopleDeleteButton') return false;                //人（5m内、5m外）（朝、昼、朝特定、昼特定、夕特定、夜、休日） 削除ボタン
        else if (item === 'template_dl_button') return false;                       //5m外算入根拠　テンプレートDLボタン
        else if (item === 'excel_dl_button') return false;                          //5m外算入根拠　エクセルDLボタン
        else if (item === 'uploadButton') return false;                             //5m外算入根拠　エクセルULボタン
        //5m外算入根拠　エクセルULボタン ユーザーのjob_category_code_oaが360、タスクidがA001の時のみ活性化
        else if (item === 'traffic5mcut') return false;                             //5m外歩行者TR算入確認　店舗入口ドア予定位置前に～
        //5m外歩行者TR算入確認　5ｍ外算入範囲から～
        //5m外歩行者TR算入確認　店舗入口ドア予定位置まで～
        //5m外歩行者TR算入確認　道路の反対側をカウントする～
        //else if (item === 'others_facility') return false;                          //その他 3行目 施設
        //その他 4行目 施設
        //else if (item === 'others_name') return false;                              //その他 駅出入口 名称
        //その他 大学短大 名称
        //その他 3行目 名称
        //その他 4行目 名称
        //else if (item === 'others_distance') return false;                          //その他 駅出入口 距離（ｋｍ）
        //その他 大学短大 距離（ｋｍ）
        //その他 3行目 距離（ｋｍ）
        //その他 4行目 距離（ｋｍ）
        //else if (item === 'others_value') return false;                           //その他 駅出入口 駅：乗降客数　他：比率
        //その他 大学短大 駅：乗降客数　他：比率
        //その他 3行目 駅：乗降客数　他：比率
        //その他 4行目 駅：乗降客数　他：比率
        //競合
      } else if (page === LOCAL_CONSTANT.NAVI.CONFLICT) {
        if (item === 'CVS_MASTER_DATA') return false;                               //「競合店設定」ボタン
        else if (item === 'CONFLICT_SM_SETTING') return false;                      //「競合SM設定」ボタン
        else if (item === 'own_deficiency_comment_button') return false;            //自店　不備コメントボタン
        else if (item === 'own_edit_button') return false;                          //自店　編集ボタン
        //残りの自店の項目非活性
        else if (item === 'conflict_deficiency_comment_button') return false;       //競合店　不備コメントボタン
        else if (item === 'conflict_edit_button') return false;                     //競合店 編集ボタン
        //残りの競合店の項目は編集ボタン活性、非活性を対応
        //商圏カット
      } else if (page === 'TRADE_AREA_CUT') {
        //if (item === 'households') return false;                                    //削除商圏 世帯数
        //else if (item === 'employees') return false;                                //削除商圏 従業員数
        if (item === 'CVS_MASTER_DATA') return false;                               //「競合店設定」ボタン
        else if (item === 'CONFLICT_SM_SETTING') return false;                      //「競合SM設定」ボタン
        else if (item === 'template_dl_button') return false;                       //「テンプレートDL」ボタン
        else if (item === 'excel_dl_button') return false;                          //「エクセルDL」ボタン
        else if (item === 'excel_ul_button') return false;                          //「エクセルUL」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //「追加」ボタン 非活性
        //「既存店・交差点確認地図」ボタン 常に活性
        if (item === LOCAL_CONSTANT.LABEL.ROUTE_CHANGE) return false;               //「ルート変更」ボタン
        else if (item === LOCAL_CONSTANT.LABEL.CHANGE) return false;                //「変更」ボタン
        else if (item === LOCAL_CONSTANT.LABEL.DELETE) return false;                //「削除」ボタン
        else if (item === "store_position") return false;                           //既存店位置
        else if (item === 'common_road') return false;                              //候補店共通道路
        else if (item === 'plan_median_strip') return false;                        //候補店中央分離帯高さ
        else if (item === 'store_median_strip') return false;                       //既存店中央分離帯高さ
        else if (item === 'straight_count') return false;                           //直進回数
        else if (item === 'turn_count') return false;                               //右左折回数
        else if (item === 'railroad_crossing') return false;                        //線路横断
        else if (item === 'river_width') return false;                              //河川幅
        else if (item === 'crossing_road_count') return false;                      //横断車線数合計
        else if (item === 'conflict_sej') return false;                             //経路競合　セブンイレブン
        else if (item === 'conflict_main_cvs') return false;                        //経路競合　LW/FM/セイコー
        else if (item === 'conflict_other_cvs') return false;                       //経路競合　その他
        else if (item === 'average_common_ratio') return false;                     //周辺既存店店番号
        //影響度判定部分の表示
        else if (item === 'impact_output_data_button') return false;                //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;                            //「アップロード」ボタン
        else if (item === 'deficiency_comment_button') return false;                //交差点　不備コメント
        else if (item === 'edit_button') return false;                             //交差点　編集ボタン
        else if (item === 'delete_button') return false;                            //交差点　削除ボタン
      //地図
      } else if  (page === LOCAL_CONSTANT.NAVI.MAP) {
          if (item === 'templateDlButton') return false;                              //テンプレートDLボタン
          else if (item === 'excelDlButton') return false;                            //エクセルDLボタン
          else if (item === 'uploadButton') return false;                             //アップロードボタン
          else if (item === 'downloadPdfButton') return false;                        //PdfDLボタン
          else if (item === 'deleteButton') return false;                             //削除ボタン
      //図面
      } else if (page === LOCAL_CONSTANT.NAVI.DRAWING) {
        if (item === 'uploadButton') return false;                                  //「アップロード」ボタン
        else if (item === 'deleteButton') return false;                             //削除ボタン
        //else if (item === 'isGreenGem') return false;                               //縁石
        //else if (item === 'isGrave') return false;                                  //墓・墓標・祠
        //else if (item === 'isMedianStrip') return false;                            //中央分離帯
        //else if (item === 'isBusStopBlackout') return false;                        //バス停・電停
        //else if (item === 'isInGreenGem') return false;                             //内縁石
        //else if (item === 'isOtherTenantParking') return false;                     //他のテナント駐車場
        //else if (item === 'isExpresswayEtcPole') return false;                      //高速道路等の支柱
        //else if (item === 'isBusLaneEtc') return false;                             //バスレーン・自転車道路
        //else if (item === 'isElectricityPole') return false;                        //電柱
        //else if (item === 'isSidewalk') return false;                               //歩道
        //else if (item === 'isOneWayStreet') return false;                           //一方通行
        //else if (item === 'isTramTrack') return false;                              //路面電車軌道
        //else if (item === 'isElectricityPoleBranchLine') return false;              //電柱の支線
        //else if (item === 'isCrosswalk') return false;                              //横断歩道
        //else if (item === 'isNoEntryRoad') return false;                            //車両進入禁止道路
        //else if (item === 'isParkingMeter') return false;                           //パーキングメーター
        //else if (item === 'isHighVoltageTowers') return false;                      //高圧線鉄塔
        //else if (item === 'isGuardrail') return false;                              //ガードレール
        //else if (item === 'isWaterwayEtc') return false;                            //水路・側溝・河川内縁石
        //else if (item === 'isOthersPlace') return false;                            //他人所有地・官地
        //else if (item === 'isHydrantEtc') return false;                             //消火栓・消化灯
        //else if (item === 'isStreetTreesEtc') return false;                         //街路樹・植木
        //else if (item === 'isRailroadCrossing') return false;                       //鉄道踏切
        //else if (item === 'isOldRoad') return false;                                //取り残された旧道
        //else if (item === 'isBillboardTowerEtc') return false;                      //広告塔・看板
        //else if (item === 'isStreetLightsEtc') return false;                        //街灯・カーブミラー
        //else if (item === 'isBridge') return false;                                 //橋
        //else if (item === 'isUnpavedRoad') return false;                            //未舗装道路
        //else if (item === 'isGarbageDump') return false;                            //ゴミ捨て場
        //else if (item === 'isRoadSigns') return false;                              //道路標識
        //else if (item === 'isOverpass') return false;                               //陸橋
        //else if (item === 'isUnmaintainedEtc') return false;                        //未整備・工事中道路
        //else if (item === 'isOther') return false;                                  //その他 来店・進入の障害物
        else if (item === 'otherLongText') return false;                            //その他 来店・進入の障害物 ロングテキスト
        //else if (item === 'hand_drawn_layout') return false;                        //手書き配置図 詳細 計算式、詳細 小計
      }
    } else if (taskGroup === 'C') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'tempUploadButton') return false;                               // 「【仮出力】売上予測集計表アップロード」ボタン 非表示
        else if (item === 'uploadButton') return false;                             // [売上予測集計表アップロード」ボタン 非表示
        else if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    } else if (taskGroup === 'D') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    } else if (taskGroup === 'E') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    }
  } else if (RM === userType) {
    if (taskGroup === 'A') {

    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    } else if (taskGroup === 'E') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    }
  } else if (GM === userType) {
    if (taskGroup === 'A') {

    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    } else if (taskGroup === 'E') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    }
  } else if (OTHER === userType) {
    if (taskGroup === 'A') {

    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    } else if (taskGroup === 'E') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'menu') return false;                                     //予測集計表サイドメニュー　活性
      }
    }
  }

  return true;
};


//表示か非表示か判定する関数
export const showHideFunc = (userType: any, taskId: any, page: string, item: string) => {
  //false 非表示、 trueが表示

  const RFC = "z003";
  const RR = "z001";  //リサーチ
  const RM = "z002";
  const GM = "z005";
  const OTHER = "z004"; //その他

  const taskGroup = groupSearchFunc(taskId);

  if (RFC === userType) {
    if (taskGroup === 'A') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'B') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      //ロングページ
      } else if (page === LOCAL_CONSTANT.NAVI.LONGPAGE) {
        if (item === 'request_button') return false             //「依頼」ボタン
      }
    } else if (taskGroup === 'C') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      //ロングページ
      } else if (page === LOCAL_CONSTANT.NAVI.LONGPAGE) {
        if (item === 'request_button') return false             //「依頼」ボタン
      }
    } else if (taskGroup === 'D') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      //ロングページ
      } else if (page === LOCAL_CONSTANT.NAVI.LONGPAGE) {
        if (item === 'request_button') return false             //「依頼」ボタン
      }
    } else if (taskGroup === 'E') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      //ロングページ
      } else if (page === LOCAL_CONSTANT.NAVI.LONGPAGE) {
        if (item === 'request_button') return false             //「依頼」ボタン
      }
    }

    //リサーチ
  } else if (RR === userType) {
    //ロングページ
    if(page === LOCAL_CONSTANT.NAVI.LONGPAGE){
      if (item === 'request_button') return false              //「依頼」ボタン
    }
    if (taskGroup === 'A') {

    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {

    } else if (taskGroup === 'E') {

    }

  } else if (RM === userType) {
    //ロングページ
    if(page === LOCAL_CONSTANT.NAVI.LONGPAGE){
      if (item === 'request_button') return false              //「依頼」ボタン
    }
    if (taskGroup === 'A') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'B') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'C') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'D') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'E') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    }
  } else if (GM === userType) {
    //ロングページ
    if(page === LOCAL_CONSTANT.NAVI.LONGPAGE){
      if (item === 'request_button') return false              //「依頼」ボタン
    }
    if (taskGroup === 'A') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'B') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'C') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'D') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    } else if (taskGroup === 'E') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
        //影響度
      } else if (page === LOCAL_CONSTANT.NAVI.IMPACT) {
        //影響度判定部分の表示
        if (item === 'impact_output_data_button') return false; //「影響度判定用データ出力」ボタン
        else if (item === 'upload_button') return false;        //「アップロード」ボタン
      }
    }
    //その他
  } else if (OTHER === userType) {
    //ロングページ
    if(page === LOCAL_CONSTANT.NAVI.LONGPAGE){
      if (item === 'request_button') return false              //「依頼」ボタン
    }
    if (taskGroup === 'A') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
      }
    } else if (taskGroup === 'B') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
      }
    } else if (taskGroup === 'C') {
      //予測集計表等
      if (page === LOCAL_CONSTANT.NAVI.SALES_FORECAST_SUMMARY_MAIN_ELEMENT_LIST) {
        if (item === 'page') return false;                         //ページ表示
        else if (item === 'tempUploadButton') return false;         //「【仮出力】売上予測集計表アップロード」ボタン
        else if (item === 'uploadButton') return false;             //[売上予測集計表アップロード」ボタン
      }
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
      }
    } else if (taskGroup === 'D') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
      }

    } else if (taskGroup === 'E') {
      //RR内部用
      if (page === LOCAL_CONSTANT.NAVI.RR_INTERNAL_USE) {
        if (item === 'uploadButton') return false;              //「アップロード」ボタン
      }
    }
  }

  return true;
};


//非表示か表示か判定する関数 デフォルト非表示
export const HideShowFunc = (userType: any, taskId: any, page: string, item: string) => {
  //false 非表示、 trueが表示

  const RFC = "z003";
  const RR = "z001";  //リサーチ
  const RM = "z002";
  const GM = "z005";
  const OTHER = "z004"; //その他

  const taskGroup = groupSearchFunc(taskId);

  if (RFC === userType) {
    if (taskGroup === 'A') {
      //ロングページ
      if (page === LOCAL_CONSTANT.NAVI.LONG_PAGE) {
        if (item === 'duplicate') return true;                     //複製
      }
    } else if (taskGroup === 'B') {
     
    } else if (taskGroup === 'C') {
     
    } else if (taskGroup === 'D') {
     
    } else if (taskGroup === 'E') {
    }

  //リサーチ
  } else if (RR === userType) {
    if (taskGroup === 'A') {

    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {

    } else if (taskGroup === 'E') {

    }

  } else if (RM === userType) {
    if (taskGroup === 'A') {

    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {

    } else if (taskGroup === 'E') {

    }
  } else if (GM === userType) {
    if (taskGroup === 'A') {

    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {

    } else if (taskGroup === 'E') {

    }
    //その他
  } else if (OTHER === userType) {
    if (taskGroup === 'A') {
    } else if (taskGroup === 'B') {

    } else if (taskGroup === 'C') {

    } else if (taskGroup === 'D') {

    } else if (taskGroup === 'E') {
    }
  }

  return false;
};

//タスクIDからタスクグループを探す関数
export const groupSearchFunc = (taskId: any) => {

  //タスクグループA
  if (taskId === 'A001' || taskId === 'B013' || taskId === 'B101' || taskId === 'C101' ||
    taskId === 'D009' || taskId === 'D101' || taskId === 'E020' || taskId === 'E101') {
    return 'A';
    //タスクグループB
  } else if (taskId === 'B002' || taskId === 'B003' || taskId === 'B004' || taskId === 'B005' ||
    taskId === 'B006' || taskId === 'C002' || taskId === 'C003' || taskId === 'C005' ||
    taskId === 'C006' || taskId === 'D002' || taskId === 'D003' || taskId === 'D006' ||
    taskId === 'E004' || taskId === 'E005' || taskId === 'E006' || taskId === 'E007' ||
    taskId === 'E008') {
    return 'B';
    //タスクグループC
  } else if (taskId === 'B007' || taskId === 'B008' || taskId === 'B009' || taskId === 'D007' ||
    taskId === 'D008' || taskId === 'D010' || taskId === 'E001' || taskId === 'E002' ||
    taskId === 'E003' || taskId === 'E009' || taskId === 'E010' || taskId === 'E011' ||
    taskId === 'E012' || taskId === 'E013' || taskId === 'E014' || taskId === 'E015') {
    return 'C';
    //タスクグループD
  } else if (taskId === 'B001' || taskId === 'B010' || taskId === 'B011' || taskId === 'B014' ||
    taskId === 'C001' || taskId === 'C004' || taskId === 'C008' || taskId === 'D001' ||
    taskId === 'D004' || taskId === 'D005' || taskId === 'D011' || taskId === 'E016' ||
    taskId === 'E017' || taskId === 'E018' || taskId === 'E021' || taskId === 'E022' ||
    taskId === 'E023' || taskId === 'E024') {
    return 'D';
    //タスクグループE
  } else if (taskId === 'B012' || taskId === 'C007' || taskId === 'E019') {
    return 'E';
  }

  return ''
};