import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { tokenLogin } from '../../utility/firebase';
import PdfViewer from '../../utility/PdfViewer';
import { setMapPdf } from '../../features/mapPdfSlice';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { postPython } from '../../api/ApiCall';
import {
  Box,
  Typography,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import { LOCAL_CONSTANT } from "../Const";
import { DeleteConfirmationModal } from '../ResearchReport/DeleteConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { setPermissionFunc } from '../components/PermissionFunc';
import {
  tradeAreaDensityFunc,
} from '../subPage/TradeArea/TradeAreaFunction';
import { getFileData, uploadFile, deleteUploadFile, getFileMetadata } from '../../utility/storage';
import MessageDialog from '../components/MessageDialog';
import { setBaseMap } from '../../features/longPageSlice';
import dayjs from 'dayjs';


const headerCell = {
  backgroundColor: '#D9D9D9',
  fontSize: '12px',
  color : 'black',
  borderBottom: '1px solid black',
  fontWeight: 'Bold'
};


export interface resultDialogProps {
  props:{
  }
}

const Map:React.FC<resultDialogProps> = ({props}) => {
  //ユーザーIDを取得
  const [userType, setUserType] = React.useState('');
  const [userData, setUserData] = React.useState<any>({});

  const user = useUserContext();
  const utilityCtx = useUtilityContext();

  const dispatch = useAppDispatch();
  const objMapPdf = useAppSelector((state) => state.mapPdf);
  //ロングページオブジェクト 候補地NOと枝を取得
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  //タスクIDを取得するため
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  //③勾配加味徒歩5分商圏と④商圏データの実査項目を計算処理 Redux
  const listMpData = useAppSelector((state) => state.tradeArea.mpDataList);

  const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("")
	const [hasButton, setHasButton] = React.useState<boolean>(false)
  const [mapExcelList, setMapExcelList] = React.useState<any>([])

  const handleValueChange = (pdfPathValue: string, labelName: string) => {
    //表示中のPDFレコードを押下時非表示
    if(objMapPdf.pdfPath === pdfPathValue){
      dispatch(setMapPdf({...objMapPdf,[labelName]: ""}));
    } else {
      dispatch(setMapPdf({...objMapPdf,[labelName]: pdfPathValue}));
    }
  };

  //ユーザーIDを取得タイミングによる画面制御
  React.useEffect(() => {
    const sync = async () => {
      await tokenLogin(window.location.href)
        .then((val) => {
          if (val === 'unprocessed') {
            // 未処理の場合はバックドロップ消さない
            return;
          }
          if (user.setUserData) {
            user.setUserData(val as string);
          }
      }).catch((e) => {
        console.log(e);
        })
    }
    sync();
    if (user.userData !== '') {
      setUserType(JSON.parse(user.userData || 'null').user_type);
      setUserData(JSON.parse(user.userData));
    }
  }, [user]);

  //モーダル開閉フラグ(削除確認)
  const [deleteConfModalOpen, setDeleteConfModalOpen] = React.useState(false);

  const handleDeleteConfModalOpen = (pathParam:string) => {
    setDeleteConfModalOpen(true);
  }
	const closeMessageDialog = () => {
		setMessage("")
    setHasButton(false)
    setMessageDialogOpen(false)
    if (downloadTemplateFilePath !== "") {
      deleteDownloadTemplateFile()
    }
	}

  React.useEffect(() => {
    if (objLocationBase.map_pdf_path) {
      const path = "/" + objLongPageParam.planAreaId + "/" + objLongPageParam.branch
      getFileMetadata(path + objLocationBase.map_pdf_path)
      .then((data: any) => {
          if (data) {
            setMapExcelList([{
              "FileName": data.name,
              "UploadDate": dayjs(data.updated).format('YYYY/MM/DD HH:mm:ss'),
              "Uplowder": "",
              "PdfPath": data.fullPath,
            }])
          }
      })
    }
  }, [objLocationBase])

  // テンプレートファイル一時ファイルの削除
  const deleteDownloadTemplateFile = async () => {
    // ファイル削除
    const deleteFile = await getFileMetadata(downloadTemplateFilePath);
    await deleteUploadFile(deleteFile, downloadTemplateFilePath)
    setDownloadTemplateFilePath("")
  }

  const [downloadTemplateFilePath, setDownloadTemplateFilePath] = React.useState<string>("")

  // テンプレートDL
  const downloadTemplate = () => {
    const params = handeleParams('create', '')
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
        try {
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DOWNLOAD_MAP_FILE)
          setMessageDialogOpen(true)
          // ファイル作成
          const result = await postPython(params)
          // ファイルダウンロード
          await downloadFirebase(result.data.data)
          // ファイル削除パス
          setDownloadTemplateFilePath(result.data.data)
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DOWNLOAD_MAP_FILE)
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e);
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DOWNLOAD_MAP_FILE)
          setHasButton(true)
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        }
    })();
  }

  const [file, setFile] = React.useState<File | null>(null)

  const handleClickFile = () => {
    const fileElem = document.getElementById("mapFileElem");
    if (fileElem) {
        fileElem.click()
    }
  }

  const handleChangeFile = (newFile: any) => {
    setFile(newFile.target.files[0]);
    newFile.target.value = '';
  };

  React.useEffect(() => {
    if (file) {
      uploadMapFile(file)
    }
  }, [file])

  // 地図ファイルアップロード
  const uploadMapFile = (file: any) => {
    const params = handeleParams('upload', file.name)
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
        try {
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CREATE_PDF)
          setMessageDialogOpen(true)
          // const result = await postPython(params)
          const path = "/" + objLongPageParam.planAreaId + "/" + objLongPageParam.branch
          // 前回ファイル削除
          if (objLocationBase.map_excel_path) {
            const deleteFile = await getFileMetadata(path + objLocationBase.map_excel_path);
            if (deleteFile) {
              await deleteUploadFile(deleteFile, path + objLocationBase.map_excel_path)
            }
          }
          if (objLocationBase.map_pdf_path) {
            const deleteFile = await getFileMetadata(path + objLocationBase.map_pdf_path);
            if (deleteFile) {
              await deleteUploadFile(deleteFile, path + objLocationBase.map_pdf_path)
            }
          }
          // ファイルアップロード
          const uploadPath = "/" + objLongPageParam.planAreaId
          + '/' + objLongPageParam.branch
          + '/excel/' + file.name
          const uploadResult = await uploadFile(file, uploadPath)
          const result = await postPython(params)
          dispatch(setBaseMap({
            ...objLocationBase,
            map_excel_path: result.data.data.map_excel_path,
            map_excel_user: result.data.data.map_excel_user,
            map_pdf_path: result.data.data.map_pdf_path,
            map_pdf_user: result.data.data.map_pdf_user
          }));
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.COMPLETE_CREATE_PDF)
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e);
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UNCOMPLETE_CREATE_PDF)
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
              utilityCtx.hideSpinner();
          }
        }
    })();
  }

  const checkDownloadExcel = () => {
    let disabled = true
    if (objLocationBase.map_excel_path) {
      disabled = setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.MAP,'excelDlButton')
    }

    return disabled
  }

  const downloadExcel = () => {
    if (objLocationBase.map_excel_path) {
      const path = "/" + objLongPageParam.planAreaId + "/" + objLongPageParam.branch
      downloadFirebase(path + objLocationBase.map_excel_path)
    }
  }

  const fileDownload = (fileName: string) => {
    if (objLocationBase.map_pdf_path) {
      const filePath = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + objLocationBase.map_pdf_path;
      (async () => {
        try {
          const url = await getFileData(filePath);
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            const link = document.createElement("a");
            document.body.appendChild(link);
            link.download = fileName;
            link.href = window.URL.createObjectURL(blob);
            link.click();
            document.body.removeChild(link);
          };
          xhr.open('GET', url as string);
          xhr.send();
        }catch(e){
          console.log(e);
        }
      })();
    }

  }

  //PDF削除
  const handleDeleteChange = () => {
    if (objLocationBase.map_excel_path && objLocationBase.map_pdf_path) {
      const params = handeleParams('delete', '')
      if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
      }
      (async () => {
        try {
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE)
          setMessageDialogOpen(true)
          const path = "/" + objLongPageParam.planAreaId + "/" + objLongPageParam.branch
          // excel削除
          const deleteExcelFile = await getFileMetadata(path + objLocationBase.map_excel_path);
          await deleteUploadFile(deleteExcelFile, path + objLocationBase.map_excel_path);
          // pdf削除
          const deletePdfFile = await getFileMetadata(path + objLocationBase.map_pdf_path);
          await deleteUploadFile(deletePdfFile, path + objLocationBase.map_pdf_path);
          const result = await postPython(params)
          //プレビューを戻す
          dispatch(setMapPdf({...objMapPdf,["pdfPath"]: ""}));
          dispatch(setBaseMap({
            ...objLocationBase,
            map_excel_path: "",
            map_excel_user: "",
            map_pdf_path: "",
            map_pdf_user: ""
          }));
          setMapExcelList([])
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE)
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e);
          setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE)
          setHasButton(true)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        }
      })();
    }
  }

  const handeleParams = (mode: string, fileName: any) => {
    return {
      mode: "regist",
      endPoint: "/make-excel-zip",
      query: {
          mode: mode,
          prop_site_no: objLongPageParam.planAreaId,
          branch: objLongPageParam.branch,
          excel_sequence: objLocationBase.location_excel_sequence,
          file_name: fileName,
          market_density: tradeAreaDensityFunc('afterModified', listMpData),
          user_data: {
            update_top_department: userData.top_department_code_OA,
            update_department: userData.department_code_OA,
            update_job: userData.job_category_code_OA,
            update_mail: userData.email.toString(),
            update_stuff_number: userData.stuff_number,
            update_name: userData.user_name
          }
      }
    }
  }

  const downloadFirebase = (path: string) => {
    getFileData(path)
    .then((url: any) => {
        const link = document.createElement('a')
        link.href = url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })
  }

  return (
    <Box style={{ width: "100%", margin: "30px" }}>
      <Typography>地図</Typography>
      <MessageDialog props={{
          open:messageDialogOpen,
          handleModal:closeMessageDialog,
          body: message,
          hasButton:hasButton,
          buttonBody:LOCAL_CONSTANT.LABEL.CLOSE,
      }}
      />
      <Box>
        <Button
          sx={{margin: '20px 10px'}}
          variant="contained"
          color="success"
          size="small"
          disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.MAP,'templateDlButton')}
          onClick={ () => downloadTemplate() }
        >{LOCAL_CONSTANT.LABEL.TEMPLATE_DL}</Button>
        <Button
          sx={{margin: '20px 10px'}}
          variant="contained"
          color="success"
          size="small"
          disabled={checkDownloadExcel()}
          onClick={ () => downloadExcel() }
        >{LOCAL_CONSTANT.LABEL.EXCEL_DL}</Button>
        <Button
          sx={{margin: '20px 10px'}}
          variant="contained"
          color="success"
          size="small"
          disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.MAP,'uploadButton')}
          onClick={ () => handleClickFile() }
        >{LOCAL_CONSTANT.LABEL.UPLOAD}</Button>
        <input
            id="mapFileElem"
            type="file"
            style={{display:'none'}}
            onChange={handleChangeFile}
            accept=".xlsm"
        />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ paddingRight: "40px" }}>
          <TableContainer sx={{ height: "140px", width: "100%",border: '1px solid black' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                  <TableCell sx={headerCell}></TableCell>
                  <TableCell sx={headerCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mapExcelList.map((val: any, index: number) => (
                    <TableRow className="tableRow" key={index}>
                        <TableCell onClick={ () => handleValueChange(val.PdfPath, "pdfPath") }>{val.FileName}</TableCell>
                        <TableCell onClick={ () => handleValueChange(val.PdfPath, "pdfPath") }>{val.UploadDate}</TableCell>
                        <TableCell onClick={ () => handleValueChange(val.PdfPath, "pdfPath") }>{val.Uplowder}</TableCell>
                        <TableCell>
                            <IconButton sx={{ color: 'black' }} 
                            disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.MAP,'downloadPdfButton')}
                            onClick={() => fileDownload(val.FileName)}><DownloadIcon /></IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            sx={{color:'black'}}
                            onClick={()=>handleDeleteConfModalOpen(val.PdfPath)}
                            disabled={setPermissionFunc(userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.MAP,'deleteButton')}
                          ><DeleteIcon/>
                          </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* 削除確認モーダル */}
        <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleDeleteChange} />
      
      </Grid>
      {/* プレビュー */}
      {objMapPdf.pdfPath  !== "" &&
      <Box sx={{ paddingTop: "60px" }}>
        <PdfViewer props={{pdfPath: objMapPdf.pdfPath}}/>
      </Box>
      }
    </Box>
  );
        
}


export default Map;
