import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useSearchParams } from "react-router-dom";
import { apiRequest } from '../../api/ApiCall';
import { useUtilityContext } from '../../utility-provider';
import { LOCAL_CONSTANT } from '../Const';
import { getDeficiencyComment } from '../../data/DataManage';
import { setLongPageParamObj } from '../../features/longPageSlice';
import { setDeficiencyComment,updateCell,updateCellSave } from '../../features/deficiencyCommentSlice';
import {
  initCommentData,
  deleteCommentData
} from '../subPage/DeficiencyComment/DeficiencyCommentFunction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';


export interface resultDialogProps {
  props: {
    width: number,
    height: number
  }
};

//スタイル
const TableHeadCell = styled(TableCell)({
  textAlign:"center",
  backgroundColor: "#00CC5B",
  color: "#fff",
  border: "solid 1px rgba(224, 224, 224, 1)"
});
const DataTableCell = styled(TableCell)({
  border: "solid 1px rgba(224, 224, 224, 1)"
});
const DataTableCommentCell = styled(TableCell)({
  border: "solid px rgba(224, 224, 224, 1)",
  padding:'0px',
});  

const DeficiencyComment: React.FC<resultDialogProps> = ({ props }) => {
  const utilityCtx = useUtilityContext();
  //URLパラメータ
  const [searchParams] = useSearchParams();
  const planAreaId = searchParams.get("planAreaId");
  const branch = searchParams.get("branch");
  //項目名用オブジェクト
  const [tableNameMaster, setTableNameMaster] = useState<any>([]);

  //不備コメント
  const listDeficiencyComment = useAppSelector((state) => state.deficiencyComment);

  //候補地NOと枝を取得
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  const dispatch = useAppDispatch();

  //ローディングフラグ
  const [loadingFlg, setLodingFlg] = useState(false);

  //不備コメント取得API
  const callApi = async (target: string) => {
    if (target === 'getDeficiencyComment') {
        return await getDeficiencyComment({
          mode: "getDeficiencyComment",
          plan_area_id: planAreaId,
          branch: branch,
        })
    }
  }

  //UPLパラメーターから候補地NOと枝番保存
  useEffect(() => {
    dispatch(
      setLongPageParamObj({
        ...objLongPageParam,
        planAreaId: planAreaId,
        branch: branch
      })
    )
  },[]);

  useEffect(() => {
    setLodingFlg(true);//ローディング開始
    //項目名オブジェクトセット
    const tableNameMasterObj = localStorage.getItem("tableNameMaster");
    if (tableNameMasterObj) {
      setTableNameMaster(JSON.parse(tableNameMasterObj));
    }
  },[]);

  //テーブル名セット
  const setTableName = (table_physics_name: string) => {
    let table_logic_name = table_physics_name
    tableNameMaster.map((row: any) => {
      if (row.table_physics_name === table_physics_name) {
        table_logic_name = row.table_logic_name
      }
    })

    return table_logic_name;
  }

  //項目名セット
  const setColumnName = (column_physics_name: string) => {
    let column_logic_name = column_physics_name
    tableNameMaster.map((row: any) => {
      if (row.column_physics_name === column_physics_name) {
        column_logic_name = row.column_logic_name
      }
    })

    return column_logic_name;
  }

  //不備コメント一覧を取得
  useEffect(() => {
    try {
      callApi('getDeficiencyComment').then((res:any) => {
        dispatch(setDeficiencyComment(initCommentData(res)));
      })
    } catch (e) {
      console.log(e);
    }
    setLodingFlg(false); //ローディング完了
  },[]);

  //テキストフィールドのスタイル
  const InputCommentSx = (index: number) => {
    return {
      '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input':{
        paddingTop:'23px',
        paddingBottom: '23px',
      } 
    }
  };

  //鉛筆ボタンをクリックしてでテキストフィールドコメント編集可能 キャンセルと更新ボタンを表示
  const handleEditChange = (id:number) => {
    const updateCommentTableData = [...listDeficiencyComment];
    dispatch(updateCell({...updateCommentTableData[id], id:id, editStatus:false})); //編集可能
  };

  //テキストフィールド入力イベント
  const handleCellChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    const updateCommentTableData = [...listDeficiencyComment];
    dispatch(updateCell({...updateCommentTableData[id], id:id, comment:e.target.value}));
  };

  //キャンセルボタンで鉛筆アイコンを表示
  const handleEditCancel = (id:number) => {
    const updateCommentTableData = [...listDeficiencyComment];
    dispatch(
      updateCell({
        ...updateCommentTableData[id],
          id:id,
          comment:listDeficiencyComment[id].initComment, //コメント戻し
          editStatus:true                                //編集不可
        }))                         
  };

  //コメント更新
  const handleCommentUpdateSave = (id: number) => {
    const updateCommentTableData = [...listDeficiencyComment];
    const params = {
      mode: "regist",
      endPoint: "/location/Deficiency/v1/update-comment",
      query: {
        plan_area_id: objLongPageParam.planAreaId,
        branch: objLongPageParam.branch,
        table_name: listDeficiencyComment[id].tableName,
        column_name: listDeficiencyComment[id].columnName,
        comment: listDeficiencyComment[id].comment
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
        try {
          await apiRequest(params);
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
            dispatch(
              updateCellSave({
                ...updateCommentTableData[id],
                id:id,
                editStatus:true, //編集不可
                comment:listDeficiencyComment[id].comment,
              })) 
          }
        } catch (e) {
          console.log(e);
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
            dispatch(
              updateCellSave({
                ...updateCommentTableData[id],
                id:id,
                editStatus:true, //編集不可
                comment:listDeficiencyComment[id].comment,
              })) 
          }
        }
    })();
  };

  //不備コメントステータスが0の場合、コメント行ごと削除
  const handleDeleteChange = (id: number) => {
    const updateCommentTableData = listDeficiencyComment.filter(row => row.id !== id); //選択したid以外を返す
    const params = {
      mode: "regist",
      endPoint: "/location/Deficiency/v1/delete",
      query: {
        plan_area_id: planAreaId,
        branch: branch,
        table_name: listDeficiencyComment[id].tableName,
        column_name: listDeficiencyComment[id].columnName
      }
    };
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
          dispatch(setDeficiencyComment(deleteCommentData(updateCommentTableData)));
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
          dispatch(setDeficiencyComment(deleteCommentData(updateCommentTableData)));
        }
      }
    })();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={"row"} sx={{ position: "fixed" }}>
        <Grid item sx={{ width: "55px", height: props.height }} />
        <Grid item sx={{ width: props.width, height: props.height, overflow: "scroll" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h3 style={{ marginLeft: "25px" }}>{LOCAL_CONSTANT.LABEL.LIST_OF_INCOMPLETE_COMMENTS}</h3>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TableContainer sx={{padding:"40px"}}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell sx={{minWidth:"110px"}}>{LOCAL_CONSTANT.LABEL.NEW_FLAG}</TableHeadCell>
                    <TableHeadCell sx={{minWidth:"110px"}}>{LOCAL_CONSTANT.LABEL.DATE}</TableHeadCell>
                    <TableHeadCell sx={{minWidth:"110px"}}>{LOCAL_CONSTANT.LABEL.PAGE}</TableHeadCell>
                    <TableHeadCell sx={{minWidth:"110px", maxWidth:"400px"}}>{LOCAL_CONSTANT.LABEL.ITEM}</TableHeadCell>
                    <TableHeadCell>{LOCAL_CONSTANT.LABEL.COMMENT}</TableHeadCell>
                    <TableHeadCell sx={{backgroundColor:"white", border:"none"}}></TableHeadCell>
                  </TableRow>
                </TableHead>
                  {/* ローディング中 */}
                  {loadingFlg ? (
                      <TableBody>
                        <TableCell sx={{border:"none"}}></TableCell>
                        <TableCell sx={{border:"none"}}></TableCell>
                        <TableCell sx={{textAlign:"center", border:"none"}}>{<CircularProgress />}</TableCell>
                        <TableCell sx={{border:"none"}}></TableCell>
                        <TableCell sx={{border:"none"}}></TableCell> 
                      </TableBody>
                    ) : (
                      // ローディング完了
                      <TableBody>
                        {listDeficiencyComment.map((item, index)=>(
                          <TableRow key={index}>
                            {/* deficiency_statusが0、1の時のみ「NEW」と表示する */}
                            {item.deficiencyStatus === "0" || item.deficiencyStatus === "1" ? (
                              <DataTableCell sx={{textAlign:"center"}}>NEW</DataTableCell>
                            ): (<DataTableCell>{item.flg}</DataTableCell>)}
                            <DataTableCell>{item.createDate}</DataTableCell>
                            <DataTableCell>{setTableName(item.tableName)}</DataTableCell>
                            <DataTableCell sx={{maxWidth:"400px"}}>{setColumnName(item.columnName)}</DataTableCell>
                            <DataTableCommentCell>
                              {listDeficiencyComment[index].editStatus === true &&
                                <TextField
                                  fullWidth
                                  disabled={listDeficiencyComment[index].editStatus}
                                  variant="outlined"
                                  sx={InputCommentSx(index)}
                                  value={item.comment}
                                />
                              }
                              {listDeficiencyComment[index].editStatus === false &&
                                <TextField
                                  fullWidth
                                  disabled={listDeficiencyComment[index].editStatus}
                                  variant="outlined"
                                  sx={InputCommentSx(index)}
                                  value={item.comment}
                                  onChange={(e)=>handleCellChange(e, index)}
                                />
                              }
                            </DataTableCommentCell>
                            <DataTableCell sx={{border:"none"}}>
                              {listDeficiencyComment[index].editStatus === true && <IconButton color="primary" onClick={()=>handleEditChange(index)} ><EditIcon/></IconButton>}
                              {/* 鉛筆アイコンをクリックしたらキャンセルと更新ボタンを表示 */}
                              {listDeficiencyComment[index].editStatus === false && <Button variant="outlined" sx={{margin:'5px'}} color="error" size="small" onClick={()=>handleEditCancel(index)}>キャンセル</Button>}
                              {listDeficiencyComment[index].editStatus === false && <Button variant="contained" sx={{margin:'5px'}} color="success" size="small" onClick={()=>handleCommentUpdateSave(index)}>更新</Button>}
                              {/* 不備コメントステータスが0の時のみ、鉛筆マークの右にゴミ箱マーク */}
                              {listDeficiencyComment[index].deficiencyStatus === '0' && <IconButton sx={{color: 'black'}} onClick={()=>handleDeleteChange(index)}><DeleteIcon/></IconButton>}
                            </DataTableCell>
                          </TableRow>
                          ))
                        }
                      </TableBody>
                    )
                  }
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

export default DeficiencyComment;