import { createSlice } from '@reduxjs/toolkit';
import { initLongPageParamObj, initLocationBaseObj, initLongPageSaveStatusObj } from '../data/type';

//Sliceの作成
export const longPageSlice = createSlice({
    name: 'longPage',
    //初期State(Typeからインポート)
    initialState: {
        param: initLongPageParamObj,
        locationBase: initLocationBaseObj,
        initLocationBase: initLocationBaseObj,
        saveStatus: initLongPageSaveStatusObj,
        duplicateStatus: false,
        surveyType: "",
        usePlanAareaId: "",
        unitEdit: false,
    },

    //toolKitを利用した値変更処理
    reducers: {
        setLongPageParamObj: (state, action) => {
            state.param = action.payload;
        },
        setLocationBase: (state, action) => {
            state.locationBase = action.payload;
        },
        setInitLocationBase: (state, action) => {
            state.initLocationBase = action.payload;
        },
        setSaveStatus: (state, action) => {
            state.saveStatus = action.payload;
        },
        setDuplicateStatus: (state, action) => {
            state.duplicateStatus = action.payload;
        },
        setLongPageSurveyType: (state, action) => {
            state.surveyType = action.payload;
        },
        setUsePlanAareaId: (state, action) => {
            state.usePlanAareaId = action.payload;
        },
        setAddSurveyCoverExcel: (state, action) => { //追加調査ページ
            state.locationBase.add_survey_cover_excel_path = action.payload.add_survey_cover_excel_path; // 追加調査_表紙_excelパス
            state.locationBase.add_survey_cover_excel_user = action.payload.add_survey_cover_excel_user; // 追加調査_表紙_excel_ユーザーID
        },
        setAddSurveyCoverPdf: (state, action) => { //追加調査ページ
            state.locationBase.add_survey_cover_pdf_path = action.payload.add_survey_cover_pdf_path; // 追加調査_表紙_pdfパス
            state.locationBase.add_survey_cover_pdf_user = action.payload.add_survey_cover_pdf_user; // 追加調査_表紙_pdf_ユーザーID
        },
        setAddSurveyDocPdfPath: (state, action) => { //追加調査ページ
            state.locationBase.add_survey_doc_pdf_path = action.payload; //追加調査_説明資料_pdfパス
        },
        setOtherDocumentExcelPath:(state, action) => { //その他資料ページ
            state.locationBase.other_document_excel_path = action.payload; //その他添付書類_excelパス
        },
        setOtherDocumentPdfPath:(state, action) => { //その他資料ページ
            state.locationBase.other_document_pdf_path = action.payload; //その他添付書類_pdfパス
        },
        setRRdocumentPdfPath: (state, action) => { //RR内部用ページ
            state.locationBase.rr_document_pdf_path = action.payload; //RR内部用_パス
        },
        setBaseMap: (state, action) => {
            state.locationBase.map_excel_path = action.payload.map_excel_path;
            state.locationBase.map_excel_user = action.payload.map_excel_user;
            state.locationBase.map_pdf_path = action.payload.map_pdf_path;
            state.locationBase.map_pdf_user = action.payload.map_pdf_user;
        },
        setUnitEdit: (state, action) => {   //通行量、競合個別編集フラグ
            state.unitEdit = action.payload;
        },
    }
});

export const {
    setLongPageParamObj,
    setLocationBase,
    setInitLocationBase,
    setSaveStatus,
    setDuplicateStatus,
    setLongPageSurveyType,
    setUsePlanAareaId,
    setAddSurveyCoverExcel,
    setAddSurveyCoverPdf,
    setAddSurveyDocPdfPath,
    setOtherDocumentExcelPath,
    setOtherDocumentPdfPath,
    setRRdocumentPdfPath,
    setBaseMap,
    setUnitEdit,
} = longPageSlice.actions;
export default longPageSlice.reducer;