import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LOCAL_CONSTANT } from "../Const";
import { tokenLogin } from "../../utility/firebase";
import { useUserContext } from "../../user-provider";
import { postPython } from '../../api/ApiCall';
import { useUtilityContext } from '../../utility-provider';
import { useSearchParams } from "react-router-dom";
import { setLongPageParamObj } from '../../features/longPageSlice';
import MessageDialog from '../components/MessageDialog';
import { getObjectFroFirebase, getFileData } from '../../utility/storage';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';


import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
  TableContainer,
  Grid,
  IconButton
} from '@mui/material';

export interface resultDialogProps {
  props: {
    width: number,
    height: number
  }
};

const headerCell = {
  backgroundColor: '#D9D9D9',
  fontSize: '12px',
  color: 'black',
  borderBottom: '1px solid black',
  fontWeight: 'Bold'
};


const PdfSummary: React.FC<resultDialogProps> = ({ props }) => {

  const dispatch = useAppDispatch();
  //URLパラメータ
  const [searchParams] = useSearchParams();
  const planAreaId = searchParams.get("planAreaId");
  const branch = searchParams.get("branch");

	const objLongPageParam = useAppSelector((state) => state.longPage.param);
	const utilityCtx = useUtilityContext();
  const [userType, setUserType] = React.useState('');
  const user = useUserContext();

  const [message, setMessage] = React.useState<string>("");
  const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
  const [hasButton, setHasButton] = React.useState<boolean>(false);
  const [pdfList, setPdfList] = React.useState<any>([])
  //UPLパラメーターから候補地NOと枝番保存
  React.useEffect(() => {
    dispatch(
      setLongPageParamObj({
        ...objLongPageParam,
        planAreaId: planAreaId,
        branch: branch
      })
    )
  },[]);

  React.useEffect(() => {
    if (objLongPageParam.planAreaId && objLongPageParam.branch) {
      getPDFList()
    }
  }, [objLongPageParam])

  //ユーザー取得
  React.useEffect(() => {
    const sync = async () => {
        await tokenLogin(window.location.href)
        .then((val) => {
            if(val === 'unprocessed'){
            // 未処理の場合はバックドロップ消さない
            return;
            }
            if(user.setUserData){
                user.setUserData(val as string);
            }
        }).catch((e) => {
            console.log(e)
        })
        }
    sync();
    if (user.userData !== '') {
      setUserType(JSON.parse(user.userData || 'null').user_type);
    }
  }, [user]);

  // firebaseからPDF一覧を取得
  const getPDFList = async () => {
    if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
    }
    const path = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/locationPDF'
    await getObjectFroFirebase(path)
    .then((data: any) => {
        (async ()=>{
            data.sort((a: any, b: any) => descTimeSort(a, b));
            setPdfList(data)
        }
        )();
    }).catch((e) => {
        console.log(e)
    }).finally(() => {
        if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
        }
    })
  }

  function descTimeSort(a: any, b: any) {
    return a.timeCreated < b.timeCreated ? 1 : -1;
  }
  

  const create_pdf = () => {
    let call_user_type = "0"
    if (userType === "z001") {
      // リサーチ
      call_user_type = "1"
    }
		const params = {
			mode: "regist",
			endPoint: "/excel-print-pdf",
			query: {
				prop_site_no: objLongPageParam.planAreaId,
				branch: objLongPageParam.branch,
        call_user_type: call_user_type,
			}
		};
		if (utilityCtx.showSpinner) {
			utilityCtx.showSpinner();
		}
		(async () => {
			try {
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CREATE_PDF)
				setMessageDialogOpen(true)
				await postPython(params)
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.COMPLETE_CREATE_PDF)
				setHasButton(true)
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			} catch (e) {
				console.log(e);
				setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UNCOMPLETE_CREATE_PDF)
				setHasButton(true)
				if (utilityCtx.hideSpinner) {
					utilityCtx.hideSpinner();
				}
			}
		})();
  }

	const closeMessageDialog = () => {
		setMessage("");
    setHasButton(false);
    setMessageDialogOpen(false);
    getPDFList()
	};


  //ファイル保存
  const fileDownload = (data: any) => {
    (async () => {
      try {
        const url = await getFileData('/' + data.fullPath);
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = data.name;
          link.href = window.URL.createObjectURL(blob);
          link.click();
          document.body.removeChild(link);
        };
        xhr.open('GET', url as string);
        xhr.send();
      }catch(e){
        console.log(e);
      }
    })();
  }

  return (
    <Box>
      <MessageDialog props={{
        open:messageDialogOpen,
        handleModal:closeMessageDialog,
        body:message,
        hasButton:hasButton,
        buttonBody:LOCAL_CONSTANT.LABEL.CLOSE
      }}
      />
      {/** 調査書PDF作成 **/}
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={'row'}>
        <Grid item sx={{ width: "55px", height: props.height }} />
        <Grid item sx={{ width: props.width, height: props.height, overflow: "scroll" }}>
          <Grid container spacing={1} sx={{paddingLeft: "20px"}}>
            <Grid item xs={12}>
              <h3>{LOCAL_CONSTANT.LABEL.PDF_LIST}</h3>
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ margin: '20px 0' }}
                variant="contained"
                color="success"
                size="small"
                onClick={create_pdf}
              >{LOCAL_CONSTANT.LABEL.SURVEY_REPORT_PDF_CREATION}</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ margin: "20px" }}>
              <TableContainer sx={{ height: "300px", width: "100%", border: '1px solid black' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                      <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                      <TableCell sx={headerCell}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pdfList.map((row: any) => (
                      <TableRow key={row.generation}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{dayjs(row.updated).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                        <TableCell>
                          <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(row)}><DownloadIcon /></IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

export default PdfSummary;
