import { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../user-provider';
import { tokenLogin } from '../../utility/firebase';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  Grid,
  Paper,
  Autocomplete,
  TextField,
  Container,
  Typography,
  styled,
  Select,
  MenuItem,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import {
  LOCAL_CONSTANT
} from '../Const'
import { getCircularHistory, planAreaSearchById } from '../../data/DataManage';

export interface resultDialogProps {
  props: {
    width: number,
    height: number
  }
};

//プルダウンリスト表示用オブジェクト
let planAreaIdList: string[] = [];
const paperSelectionList = [
  { value: '', label: '　' },
  { value: '0', label: '緑紙' },
  { value: '1', label: '白紙' },
];
const commentPoAList = [
  { value: '', label: '　' },
  { value: 'having', label: '有' },
  { value: 'nothing', label: '無' },
];
//テーブル表示用オブジェクト
let listDataContent: GridRowsProp = [];

//テーブルツールバー箇所
const costomToolBar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: LOCAL_CONSTANT.NAVI.CIRCULAR_HISTORY,
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
};

//スタイル
const InputFormDataCell = styled(TableCell)({
  padding: "5px 16px"
});
const DataTableHeader = styled(TableHead)({
  backgroundColor: "#024689"
});
const DataTableHeaderCell = styled(TableCell)({
  color: "#ffffff"
});
const SaveExButton = styled(Button)({
  backgroundColor: "#024689",
  color: "#ffffff"
});
const FormButton = styled(Button)({
  color: "#0ad90a",
  border: "solid 1px"
});
const styles = {
  dataGrid: {
    ".MuiDataGrid-toolbarContainer": {
      borderBottom: "solid 1px rgba(224, 224, 224, 1)",
      display: "block"
    },
    ".MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)": {
      borderRight: "solid 1px rgba(224, 224, 224, 1) !important"
    },
    ".MuiDataGrid-columnHeaders": {
      backgroundColor: "#024689",
      color: "#fff",
    },
    ".MuiDataGrid-columnHeader": {
      border: "solid 1px rgba(224, 224, 224, 1)"
    },
    ".MuiDataGrid-virtualScrollerContent": {
      backgroundColor: "white"
    },
    ".MuiButtonBase-root": {
      float: "right"
    },
  },
  select: {
    ".MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
      width: "60%"
    }
  }
}
const CircularHistory: React.FC<resultDialogProps> = ({ props }) => {

  const location = useLocation();
  const user = useUserContext();
  //ユーザータイプ
  const [userType, setUserType] = useState<string>("");
  //データグリッドローディングフラグ
  const [dataGridLoading, setDataGridLoading] = useState(false);
  //URLパラメータ
  const [searchParams] = useSearchParams();
  const planAreaId = searchParams.get("planAreaId");
  const branch = searchParams.get("branch");
  const [planAreaName, setPlanAreaName] = useState();
  //テーブル表示用オブジェクト
  const [rows, setRows] = useState(listDataContent);
  //日付(開始日)
  const [fromDate, setFromDate] = useState("");
  //日付(終了日)
  const [toDate, setToDate] = useState("");
  //候補地No
  const [selectPlanAreaId, setSelectPlanAreaId] = useState<string | null>("");
  //候補地名
  const [selectPlanAreaName, setSelectPlanAreaName] = useState<string | null>("");
  //コメントタイプ
  const [commentType, setCommentType] = useState<string>("");
  //コメント有無
  const [commentPoA, setCommentPoA] = useState<string>("");
  //テーブル項目
  const columns: GridColDef[] = [
    { field: 'create_date', headerName: LOCAL_CONSTANT.LABEL.REQUEST_DATE, width: 160, headerAlign: 'center', align: 'center', disableColumnMenu: true, },
    { field: 'plan_area_department_name', headerName: LOCAL_CONSTANT.LABEL.DEPARTMENT, flex: 100, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'plan_area_district_name', headerName: LOCAL_CONSTANT.LABEL.DISTRICT, flex: 90, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'plan_area_staff_name', headerName: LOCAL_CONSTANT.LABEL.RFC, flex: 80, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'plan_area_id', headerName: LOCAL_CONSTANT.LABEL.PLAN_AREA_NO, flex: 90, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'plan_area_name', headerName: LOCAL_CONSTANT.LABEL.PLAN_AREA_NAME, flex: 100, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'flag', headerName: LOCAL_CONSTANT.LABEL.FLAG, flex: 70, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'branch', headerName: LOCAL_CONSTANT.LABEL.BRANCH, flex: 70, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'status_name', headerName: LOCAL_CONSTANT.LABEL.STATUS, flex: 100, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'pic_type_name', headerName: LOCAL_CONSTANT.LABEL.IN_CHARGE, flex: 100, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'pic_name', headerName: LOCAL_CONSTANT.LABEL.MANAGER, flex: 100, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'task_name', headerName: LOCAL_CONSTANT.LABEL.TASK, flex: 100, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'comment_type', headerName: LOCAL_CONSTANT.LABEL.GREEN_PAPER_BLANK_PAPER, flex: 90, headerAlign: 'center', disableColumnMenu: true, },
    { field: 'commentText', headerName: LOCAL_CONSTANT.LABEL.COMMENT, flex: 100, headerAlign: 'center', disableColumnMenu: true, },
  ];
  //テーブル表示列管理用ステート
  const [visibleColumns, setVisibleColumns] = useState(columns.map((col)=>col.field));

  //画面遷移元フラグ(ポータル：true　調査書：false)
  let portalFlg = false;

  //ポータル画面からの遷移の場合フラグ=true
  if (location.pathname === "/Portal/CircularHistory") {
    portalFlg = true;
  }

  //API呼び出し
  const callApi = async (target: string) => {
    //テーブル表示用データ取得(検索時)
    if (target === 'getCircularHistory') {
      return await getCircularHistory({
        mode: "getCircularHistory",
        plan_area_id: selectPlanAreaId,
        plan_area_name: selectPlanAreaName,
        fromDate: fromDate,
        toDate: toDate,
        comment_type: commentType,
        commentPoA: commentPoA
      })
    }
    //候補地名取得(調査書)
    if (target === 'planAreaSearchById') {
      return await planAreaSearchById({
        mode: "planAreaSearchById",
        id: planAreaId,
        manageCitiesFlg: false,
        manageCities: "",
        manageStep: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "44", "99"],
        managePlansOutsideCities: "",
      })
    }
  };

  //検索
  const handleSearch = () => {
    setDataGridLoading(true);
    //検索API
    try {
      callApi('getCircularHistory').then((res: any) => {
        res.map((e: any, i: any) => {
          res[i]["id"] = String(i + 1); //ID項目追加
          if (e.reception_no !== null) {
            res[i]["flag"] = e.reception_no.substr(8, 1); //フラグ
          }
        });
        setRows(res);
        setDataGridLoading(false);
      }).catch((e) => {
        console.log(e);
      })
    } catch (e) {
      console.log(e);
    }
  };

  //ユーザー取得タイミングによる画面制御
  useEffect(() => {
    const sync = async () => {
      await tokenLogin(window.location.href)
        .then((val) => {
          if (val === 'unprocessed') {
            // 未処理の場合はバックドロップ消さない
            return;
          }
          if (user.setUserData) {
            user.setUserData(val as string);
          }
        }).catch((e) => {
          console.log(e)
        })
    }
    sync();
    const userData = JSON.parse(user.userData || 'null');
    if (userData !== '') {
      console.log(userData);
      setUserType(userData?.user_type ?? "");
      //テーブルオブジェクトの設定
      let newVisibleColumns = columns.map((col) => col.field);

      //リサーチ以外の場合は非表示カラムを設定
      if(userData?.user_type !== "z001"){
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'plan_area_department_name');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'plan_area_district_name');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'plan_area_staff_name');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'plan_area_id');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'plan_area_name');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'flag');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'branch');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'status_name');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'pic_name');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'comment_type');
        newVisibleColumns = newVisibleColumns.filter(col => col !== 'commentText');
      }
      setVisibleColumns(newVisibleColumns);
    }
  }, [user]);

  //初期データ取得処理
  useEffect(() => {
    //候補地情報プルダウンリスト設定
    const dataObj = localStorage.getItem("planAreaList");
    if (dataObj !== null) {
      const jsonDataObj = JSON.parse(dataObj);
      jsonDataObj.map((e: any) => {
        planAreaIdList.push(e.id);
      })
    }
    //候補地Noから候補地名取得(調査書のみ)
    if (!portalFlg) {
      try {
        callApi('planAreaSearchById').then((res: any) => {
          setPlanAreaName(res[0].plan_area_name);
          setSelectPlanAreaName(res[0].plan_area_name);
          setSelectPlanAreaId(planAreaId);
        }).catch((e) => {
          console.log(e);
        })
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} sx={{ height: "48px" }} />
      </Grid>
      <Grid container direction={'row'} sx={{ position: "fixed" }}>
        <Grid item sx={{ width: "55px", height: props.height }} />
        <Grid item sx={{ width: props.width, height: props.height, overflow: "scroll" }}>
          <Grid container spacing={6} rowSpacing={3}>

            {/* コンテンツボディ */}
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={6} rowSpacing={2} columnSpacing={1} sx={{ alignItem: "center" }}>

                  {/* 入力フォーム部分 */}
                  <Grid item xs={6}>
                    <Paper sx={{ margin: "10px" }}>
                      <Table>
                        <TableBody>
                          {userType === "z001" &&
                          <TableRow>
                            <InputFormDataCell>{LOCAL_CONSTANT.LABEL.FROM_TO}</InputFormDataCell>
                            <InputFormDataCell>
                              <Grid container rowSpacing={1} columnSpacing={3} sx={{ alignItems: "center" }}>
                                <Grid item xs={5}><TextField type="date" onChange={(e) => { setFromDate(e.target.value) }}></TextField></Grid>
                                <Grid item xs={1} sx={{ paddingLeft: "5px" }}><Box>～</Box></Grid>
                                <Grid item xs={5}><TextField type="date" onChange={(e) => { setToDate(e.target.value) }}></TextField></Grid>
                              </Grid>
                            </InputFormDataCell>
                          </TableRow>
                          }
                          <TableRow>
                            <InputFormDataCell>{LOCAL_CONSTANT.LABEL.PLAN_AREA_NO}</InputFormDataCell>
                            <InputFormDataCell>
                              {portalFlg ?
                                <Autocomplete
                                  value={selectPlanAreaId}
                                  onChange={(e, val) => { setSelectPlanAreaId(val === null ? "" : val) }}
                                  options={planAreaIdList}
                                  getOptionLabel={(planAreaIdList) => planAreaIdList}
                                  renderInput={(params) => (<TextField {...params} variant="outlined"></TextField>)}
                                ></Autocomplete>
                                :
                                <TextField
                                  value={planAreaId}
                                  InputProps={{ readOnly: true }}
                                  variant="filled">
                                </TextField>
                              }
                            </InputFormDataCell>
                          </TableRow>
                          <TableRow>
                            <InputFormDataCell>{LOCAL_CONSTANT.LABEL.PLAN_AREA_NAME}</InputFormDataCell>
                            <InputFormDataCell>
                              {portalFlg ?
                                <TextField onChange={(e) => { setSelectPlanAreaName(e.target.value) }}></TextField>
                                :
                                <TextField
                                  value={planAreaName}
                                  InputProps={{ readOnly: true }}
                                  variant="filled">
                                </TextField>
                              }
                            </InputFormDataCell>
                          </TableRow>
                          {userType === "z001" &&
                          <TableRow>
                            <InputFormDataCell>{LOCAL_CONSTANT.LABEL.GREEN_PAPER_BLANK_PAPER}</InputFormDataCell>
                            <InputFormDataCell sx={styles.select}>
                              <Select
                                onChange={(e) => { setCommentType(e.target.value) }}
                                value={commentType}
                              >
                                {paperSelectionList !== undefined &&
                                  paperSelectionList.map((val, i) => (
                                    <MenuItem key={i} value={val.value}>{val.label}</MenuItem>
                                  ))}
                              </Select>
                            </InputFormDataCell>
                          </TableRow>
                          }
                          {userType === "z001" ?
                          <TableRow>
                            <InputFormDataCell>{LOCAL_CONSTANT.LABEL.COMMENT_YN}</InputFormDataCell>
                            <InputFormDataCell sx={styles.select}>
                              <Select
                                onChange={(e) => { setCommentPoA(e.target.value) }}
                                value={commentPoA}
                              >
                                {commentPoAList !== undefined &&
                                  commentPoAList.map((val, i) => (
                                    <MenuItem key={i} value={val.value}>{val.label}</MenuItem>
                                  ))}
                              </Select>
                            </InputFormDataCell>
                            <InputFormDataCell><SaveExButton onClick={handleSearch}>{LOCAL_CONSTANT.LABEL.SEARCH}</SaveExButton></InputFormDataCell>
                          </TableRow>
                          :
                          <TableRow>
                            <InputFormDataCell></InputFormDataCell>
                            <InputFormDataCell></InputFormDataCell>
                            <InputFormDataCell><SaveExButton onClick={handleSearch}>{LOCAL_CONSTANT.LABEL.SEARCH}</SaveExButton></InputFormDataCell>
                          </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>

                  {/* テーブル表示部分 */}
                  <Grid item xs={12}>
                    <Box sx={{ padding: "10px" }}>
                      <DataGrid
                        columns={columns.filter(col => visibleColumns.includes(col.field))}
                        rows={rows}
                        sx={styles.dataGrid}
                        density="compact"
                        autoHeight
                        components={{ Toolbar: costomToolBar }}
                        loading={dataGridLoading}
                      />
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CircularHistory;