import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { LOCAL_CONSTANT } from '../../Const';
import {
    Box,
    Button,
    Typography,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
    FormControl,
} from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import {
    checkDisabled,
    setSelecter,
    setSelecterDisplay,
    setSelecterValue,
    hasConflictSubject,
    closingTime,
    displayClosingTime,
    validationCheck,
} from './AddConflictFunction';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import {
    CVS_MASTER_DATA,
    SM_DRUG_MASTER_DATA,
} from '../../ResearchReport/Conflict';
import Comment from '../../components/Comment';
import { setPermissionFunc } from '../../components/PermissionFunc';
import MessageDialog from '../../components/MessageDialog';
import { setUnitEdit } from '../../../features/longPageSlice';

export interface conflictListProps {
    props:{
        ownStore: any;
        locationConflict: any;
        setReload: any;
        type: string;
        targetRadius: string;
        userType: string | null;
    }
}

const inactive = {
    minHeight: '50px',
    height: '50px',
    minWidth: '240px',
    width: '240px',
    padding: '10px 0',
    border: '1px solid black',
    backgroundColor: 'rgba(0, 0, 0, 0.06)'
};


const hours = [
    {code_value: "00", code_display: "0"},
    {code_value: "01", code_display: "1"},
    {code_value: "02", code_display: "2"},
    {code_value: "03", code_display: "3"},
    {code_value: "04", code_display: "4"},
    {code_value: "05", code_display: "5"},
    {code_value: "06", code_display: "6"},
    {code_value: "07", code_display: "7"},
    {code_value: "08", code_display: "8"},
    {code_value: "09", code_display: "9"},
    {code_value: "10", code_display: "10"},
    {code_value: "11", code_display: "11"},
    {code_value: "12", code_display: "12"},
    {code_value: "13", code_display: "13"},
    {code_value: "14", code_display: "14"},
    {code_value: "15", code_display: "15"},
    {code_value: "16", code_display: "16"},
    {code_value: "17", code_display: "17"},
    {code_value: "18", code_display: "18"},
    {code_value: "19", code_display: "19"},
    {code_value: "20", code_display: "20"},
    {code_value: "21", code_display: "21"},
    {code_value: "22", code_display: "22"},
    {code_value: "23", code_display: "23"},
]
const minutes = [
    {code_value: "00", code_display: "0"},
    {code_value: "15", code_display: "15"},
    {code_value: "30", code_display: "30"},
    {code_value: "45", code_display: "45"},
]


export const ConflictList:React.FC<conflictListProps> = ({props}) => {
    const utilityCtx = useUtilityContext();
    const dispatch = useAppDispatch();

    const [draw, setDraw] = React.useState<boolean>(false)
    const [codeMaster, setCodeMaster] = React.useState<any>();
    const [storeData, setStoreData] = React.useState<any>(undefined)
    const [conflictData, setConflictData] = React.useState<any>([])
    const [editStore, setEditStore] = React.useState<boolean>(false)
    const [editStoreDisabled, setEditStoreDisabled] = React.useState<boolean>(true)
    const [editConflict, setEditConflict] = React.useState<boolean>(false)
    const [editDisabled, setEditDisabled] = React.useState<boolean>(true)

    const [originStoreData, setOriginStoreData] = React.useState<any>(undefined)
    const [originConflictData, setOriginConflictData] = React.useState<any>([])

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    // タスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    // 表紙
    const objCover = useAppSelector((state) => state.cover.obj);
    // 出店条件
    const objStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.obj);
    // 寄付
    const objDonation = useAppSelector((state) => state.donation.obj);
    // 
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("")
	const [hasButton, setHasButton] = React.useState<boolean>(false)

	const closeMessageDialog = () => {
		setMessage("")
        setHasButton(false)
        setMessageDialogOpen(false)
	}

    const header = () => {
        const tmp: any = []
        const length = props.type === CVS_MASTER_DATA ? 20 : 10
        for (let i = 1; i <= length; i++) {
            tmp.push({'key': i, 'label': LOCAL_CONSTANT.LABEL.CONFLICT_STORES+i.toString()})
        }
    
        return tmp
    }

    const CVSLabel = () => {
        if (props.type === CVS_MASTER_DATA) {
            //requireStatusが0が任意、1が必須でアスタリスクが1つ,2が条件合致時必須でアスタリスクが2つ
            return [
                {"key": 1, "fontSize": '1rem', "cols": 0, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": "", 'keyName': LOCAL_CONSTANT.LABEL.OWN_STORE, 'SELECT_KEY': '',"requireStatus":0},
                {"key": 2, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.COMPETITIVE_TARGET, 'keyName': 'location_conflict_type', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 3, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.FIVE_MINUTES_WALK_WITHIN_COMMERCIAL_AREA, 'keyName': 'include_commercial_area', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 4, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('bland_name'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.CHAIN_NAME, 'keyName': 'bland_name', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 5, "fontSize": '1rem', "cols": 1, "target": "data", "value": objCover['surveyName'], "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.STORE_NAME, 'keyName': 'tenpo_name', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 6, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('business_hours'), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.BUSINESS_HOURS, 'keyName': 'business_hours', 'SELECT_KEY': 'business_hours',"requireStatus":1},
                {"key": 7, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "TIME-LABEL", "labelName": LOCAL_CONSTANT.LABEL.CLOSING_TIME, 'keyName': 'closing_time', 'SELECT_KEY': 'closing_time',"requireStatus":2},
                {"key": 8, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('handle_category_liquor'), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.LIQUOR_LICENSE, 'keyName': 'handle_category_liquor', 'SELECT_KEY': 'marubatu',"requireStatus":1},
                {"key": 9, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('handle_category_tobacco'), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.TOBACCO_LICENSE, 'keyName': 'handle_category_tobacco', 'SELECT_KEY': 'marubatu',"requireStatus":1},
                {"key": 10, "fontSize": '1rem', "cols": 1, "target": "db-select", "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.LOCATION, 'keyName': 'location', 'SELECT_KEY': 'location', 'SELECTER': setSelecter(codeMaster, 'location'),"requireStatus":1},
                {"key": 11, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('sales_floor_area_size'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.SALES_FLOOR_AREA +'('+ LOCAL_CONSTANT.LABEL.SQUARE_METRE+')', 'keyName': 'sales_floor_area_size', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 12, "fontSize": '1rem', "cols": 1, "target": "db-text", "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.GONDOLA_SCALE, 'keyName': 'gondola_scale', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 13, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('handle_category_parking'), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.PARKING_AVAILABLE, 'keyName': 'handle_category_parking', 'SELECT_KEY': 'common_yesnone_kana',"requireStatus":1},
                {"key": 14, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('store_frontage'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.STORE_FRONTAGE, 'keyName': 'store_frontage', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 15, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('parking_area_size'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.PARKING_AREA +'('+ LOCAL_CONSTANT.LABEL.SQUARE_METRE+')', 'keyName': 'parking_area_size', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 16, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('number_of_parking'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.NUMBER_OF_PARKING_SPACES, 'keyName': 'number_of_parking', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 17, "fontSize": '1rem', "cols": 2, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.FRONT, 'keyName': 'front_parking_tangent', 'SELECT_KEY': '',
                    "colsLabel": [
                        {"key": 1, "fontSize": '1rem', "value": storeValue('front_parking_tangent'), "labelName": LOCAL_CONSTANT.LABEL.PARKING_LOT_TANGENT, "keyName": "front_parking_tangent","requireStatus":1},
                        {"key": 2, "fontSize": '1rem', "value": storeValue('front_first_entrance'), "labelName": LOCAL_CONSTANT.LABEL.FIRST_ENTRANCE, "keyName": "front_first_entrance","requireStatus":1},
                        {"key": 3, "fontSize": '1rem', "value": storeValue('front_first_depth'), "labelName": LOCAL_CONSTANT.LABEL.FIRST_DEPTH, "keyName": "front_first_depth","requireStatus":1},
                        {"key": 4, "fontSize": '1rem', "value": storeValue('front_second_entrance'), "labelName": LOCAL_CONSTANT.LABEL.SECOND_ENTRANCE, "keyName": "front_second_entrance","requireStatus":2},
                        {"key": 5, "fontSize": '1rem', "value": storeValue('front_second_depth'), "labelName": LOCAL_CONSTANT.LABEL.SECOND_DEPTH, "keyName": "front_second_depth","requireStatus":2},
                    ]
                },
                {"key": 18, "fontSize": '1rem', "cols": 1, "target": "db-value", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.TYPE_OF_SIDE_ROAD, 'keyName': 'side_road_type', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 19, "fontSize": '1rem', "cols": 2, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.SIDE+"（"+LOCAL_CONSTANT.LABEL.BACK+"）", 'keyName': 'side_parking_tangent', 'SELECT_KEY': '',
                    "colsLabel": [
                        {"key": 1, "fontSize": '1rem', "value": storeValue('side_parking_tangent'), "labelName": LOCAL_CONSTANT.LABEL.PARKING_LOT_TANGENT, "keyName": "side_parking_tangent","requireStatus":2},
                        {"key": 2, "fontSize": '1rem', "value": storeValue('side_first_entrance'), "labelName": LOCAL_CONSTANT.LABEL.FIRST_ENTRANCE, "keyName": "side_first_entrance","requireStatus":2},
                        {"key": 3, "fontSize": '1rem', "value": storeValue('side_first_depth'), "labelName": LOCAL_CONSTANT.LABEL.FIRST_DEPTH, "keyName": "side_first_depth","requireStatus":2},
                        {"key": 4, "fontSize": '1rem', "value": storeValue('side_second_entrance'), "labelName": LOCAL_CONSTANT.LABEL.SECOND_ENTRANCE, "keyName": "side_second_entrance","requireStatus":2},
                        {"key": 5, "fontSize": '1rem', "value": storeValue('side_second_depth'), "labelName": LOCAL_CONSTANT.LABEL.SECOND_DEPTH, "keyName": "side_second_depth","requireStatus":2},
                    ]
                },
                {"key": 20, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.ESTIMATED_DAILY_SALES+'('+LOCAL_CONSTANT.LABEL.THOUSAND_YEN+')', 'keyName': 'estimated_daily_sales', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 21, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE +'('+ LOCAL_CONSTANT.LABEL.METRE+')', 'keyName': 'direct_distance', 'SELECT_KEY': '',"requireStatus":1},
                {"key": 22, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.ROAD_DISTANCE +'('+ LOCAL_CONSTANT.LABEL.METRE+')', 'keyName': 'road_distance', 'SELECT_KEY': '',"requireStatus":2},
                {"key": 23, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.COMMON_ROAD_IN_FRONT_OF_CANDIDATE, 'keyName': 'plan_area_shared_road', "SELECT_KEY": 'common__shared_road',"requireStatus":2},
                {"key": 24, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.DIRECTION, 'keyName': 'plan_area_direction', "SELECT_KEY": 'plan_area_direction',"requireStatus":2},
                {"key": 25, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.COMMON_ROAD_BEFORE_COMPETITION, 'keyName': 'conflict_shared_road', "SELECT_KEY": 'common__shared_road',"requireStatus":2},
                {"key": 26, "fontSize": '0.8rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.PRESENCE_OF_COMMON_ROAD_SEPARATION_STRIP_BEFORE_COMPETITION, 'keyName': 'conflict_median_strip', "SELECT_KEY": 'common_yesnone_kana',"requireStatus":2},
                {"key": 27, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.LEFT_RIGHT_TURN_INDICATOR, 'keyName': 'count_left_right_turns', "SELECT_KEY": 'count_left_right_turns',"requireStatus":2},
                {"key": 28, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.STRAIGHT_GAUGE, 'keyName': 'count_straight', "SELECT_KEY": 'count_straight',"requireStatus":2},
            ]
        }
        return [
            {"key": 1, "fontSize": '1rem', "cols": 0, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": "", 'keyName': LOCAL_CONSTANT.LABEL.OWN_STORE, 'SELECT_KEY': '', "requireStatus": 0},
            {"key": 2, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.COMPETITIVE_TARGET, 'keyName': 'location_conflict_type', 'SELECT_KEY': '', "requireStatus": 1},
            {"key": 3, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.FIVE_MINUTES_WALK_WITHIN_COMMERCIAL_AREA, 'keyName': 'include_commercial_area', 'SELECT_KEY': '',"requireStatus": 1},
            {"key": 4, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('bland_name'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.CHAIN_NAME, 'keyName': 'bland_name', 'SELECT_KEY': '',"requireStatus": 1},
            {"key": 5, "fontSize": '1rem', "cols": 1, "target": "data", "value": objCover['surveyName'], "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.STORE_NAME, 'keyName': 'tenpo_name', 'SELECT_KEY': '',"requireStatus": 1},
            {"key": 6, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('business_hours'), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.BUSINESS_HOURS, 'keyName': 'business_hours', 'SELECT_KEY': 'business_hours',"requireStatus": 1},
            {"key": 7, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "TIME-LABEL", "labelName": LOCAL_CONSTANT.LABEL.CLOSING_TIME, 'keyName': 'closing_time', 'SELECT_KEY': 'closing_time',"requireStatus": 2},
            {"key": 8, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('sales_floor_area_size'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.SALES_FLOOR_AREA +'('+ LOCAL_CONSTANT.LABEL.SQUARE_METRE+')', 'keyName': 'sales_floor_area_size', 'SELECT_KEY': '',"requireStatus": 1},
            {"key": 9, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('handle_category_parking'), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.PARKING_AVAILABLE, 'keyName': 'handle_category_parking', 'SELECT_KEY': 'common_yesnone_kana',"requireStatus": 1},
            {"key": 10, "fontSize": '1rem', "cols": 1, "target": "data", "value": storeValue('parking_area_size'), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.PARKING_AREA +'('+ LOCAL_CONSTANT.LABEL.SQUARE_METRE+')', 'keyName': 'parking_area_size', 'SELECT_KEY': '',"requireStatus": 1},
            {"key": 11, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE +'('+ LOCAL_CONSTANT.LABEL.METRE+')', 'keyName': 'direct_distance', 'SELECT_KEY': '',"requireStatus": 1},
            {"key": 12, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "TEXT", "labelName": LOCAL_CONSTANT.LABEL.ROAD_DISTANCE +'('+ LOCAL_CONSTANT.LABEL.METRE+')', 'keyName': 'road_distance', 'SELECT_KEY': '',"requireStatus": 2},
            {"key": 13, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.COMMON_ROAD_IN_FRONT_OF_CANDIDATE, 'keyName': 'plan_area_shared_road', "SELECT_KEY": 'common__shared_road',"requireStatus": 2},
            {"key": 14, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.DIRECTION, 'keyName': 'plan_area_direction', "SELECT_KEY": 'plan_area_direction',"requireStatus": 2},
            {"key": 15, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.COMMON_ROAD_BEFORE_COMPETITION, 'keyName': 'conflict_shared_road', "SELECT_KEY": 'common__shared_road',"requireStatus": 2},
            {"key": 16, "fontSize": '0.8rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.PRESENCE_OF_COMMON_ROAD_SEPARATION_STRIP_BEFORE_COMPETITION, 'keyName': 'conflict_median_strip', "SELECT_KEY": 'common_yesnone_kana',"requireStatus": 2},
            {"key": 17, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.LEFT_RIGHT_TURN_INDICATOR, 'keyName': 'count_left_right_turns', "SELECT_KEY": 'count_left_right_turns',"requireStatus": 2},
            {"key": 18, "fontSize": '1rem', "cols": 1, "target": "gray-data", "value": storeValue(''), "type": "SELECT", "labelName": LOCAL_CONSTANT.LABEL.STRAIGHT_GAUGE, 'keyName': 'count_straight', "SELECT_KEY": 'count_straight',"requireStatus": 2}
        ]
    }
    
    const columnList = () => {
        if (props.type === CVS_MASTER_DATA) {
            return [
                {key: 1, keyName: 'location_conflict_type', disabled: '', type: 'location_conflict_type', labelName: '競合対象'},
                {key: 2, keyName: 'include_commercial_area', disabled: '', type: 'select', labelName: '徒歩5分商圏内外', 'SELECTER': setSelecter(codeMaster, 'include_commercial_area'), 'SELECT_KEY': 'include_commercial_area'},
                {key: 3, keyName: 'bland_name', disabled: '', type: 'label', labelName: 'チェーン名'},
                {key: 4, keyName: 'tenpo_name', disabled: '', type: 'text', labelName: '店名'},
                {key: 5, keyName: 'business_hours', disabled: '', type: 'select', labelName: '営業時間', 'SELECTER': setSelecter(codeMaster, 'business_hours'), 'SELECT_KEY': 'business_hours'},
                {key: 6, keyName: 'closing_time', disabled: 'closing', type: 'time-label', labelName: '閉店時間'},
                {key: 7, keyName: 'handle_category_liquor', disabled: '', type: 'select', labelName: '酒免許', 'SELECTER': setSelecter(codeMaster, 'marubatu'), 'SELECT_KEY': 'marubatu'},
                {key: 8, keyName: 'handle_category_tobacco', disabled: '', type: 'select', labelName: '煙草免許', 'SELECTER': setSelecter(codeMaster, 'marubatu'), 'SELECT_KEY': 'marubatu'},
                {key: 9, keyName: 'location', disabled: '', type: 'select', labelName: '立地', 'SELECTER': setSelecter(codeMaster, 'location'), 'SELECT_KEY': 'location'},
                {key: 10, keyName: 'sales_floor_area_size', disabled: '', type: 'text',labelName: '売場面積(㎡)'},
                {key: 11, keyName: 'gondola_scale', disabled: '', type: 'text',labelName: 'ゴンドラ尺数'},
                {key: 12, keyName: 'handle_category_parking', disabled: '', type: 'select',labelName: '駐車場有無', 'SELECTER': setSelecter(codeMaster, 'common_yesnone_kana'), 'SELECT_KEY': 'common_yesnone_kana'},
                {key: 13, keyName: 'store_frontage', disabled: 'store_frontage', type: 'text',labelName: '店舗間口'},
                {key: 14, keyName: 'parking_area_size', disabled: 'parking', type: 'text',labelName: '駐車場面積(㎡)'},
                {key: 15, keyName: 'number_of_parking', disabled: 'parking', type: 'text',labelName: '駐車場台数'},
                {key: 16, keyName: 'front_parking_tangent', disabled: 'front', type: 'text',labelName: '前面駐車場接線(m)'},
                {key: 17, keyName: 'front_first_entrance', disabled: 'front', type: 'text',labelName: '前面第一進入口(m)'},
                {key: 18, keyName: 'front_first_depth', disabled: 'front', type: 'text',labelName: '前面第一奥行き(m)'},
                {key: 19, keyName: 'front_second_entrance', disabled: 'front', type: 'text',labelName: '前面第二進入口(m)'},
                {key: 20, keyName: 'front_second_depth', disabled: 'front', type: 'text',labelName: '前面第二奥行き(m)'},
                {key: 21, keyName: 'side_road_type', disabled: 'side_road_type', type: 'codemaster-value',labelName: '側道有無種類'},
                {key: 22, keyName: 'side_parking_tangent', disabled: 'side', type: 'text',labelName: '側道(裏道)駐車場接線(m)'},
                {key: 23, keyName: 'side_first_entrance', disabled: 'side', type: 'text',labelName: '側道(裏道)第一進入口(m)'},
                {key: 24, keyName: 'side_first_depth', disabled: 'side', type: 'text',labelName: '側道(裏道)第一奥行き(m)'},
                {key: 25, keyName: 'side_second_entrance', disabled: 'side', type: 'text',labelName: '側道第二進入口(m)'},
                {key: 26, keyName: 'side_second_depth', disabled: 'side', type: 'text',labelName: '側道第二奥行き(m)'},
                {key: 27, keyName: 'estimated_daily_sales', disabled: '', type: 'text',labelName: '推定日販(千円)'},
                {key: 28, keyName: 'direct_distance', disabled: '', type: 'round',labelName: '直線距離'},
                {key: 29, keyName: 'road_distance', disabled: '', type: 'round',labelName: '道なり距離'},
                {key: 30, keyName: 'plan_area_shared_road', disabled: 'plan_area_shared_road', type: 'select',labelName: '候補前共通道路', 'SELECTER': setSelecter(codeMaster, 'common__shared_road'), 'SELECT_KEY': 'common__shared_road'},
                {key: 31, keyName: 'plan_area_direction', disabled: 'plan_area_direction', type: 'select',labelName: '方向', 'SELECTER': setSelecter(codeMaster, 'plan_area_direction'), 'SELECT_KEY': 'plan_area_direction'},
                {key: 32, keyName: 'conflict_shared_road', disabled: 'conflict_shared_road', type: 'select',labelName: '競合前共通道路', 'SELECTER': setSelecter(codeMaster, 'common__shared_road'), 'SELECT_KEY': 'common__shared_road'},
                {key: 33, keyName: 'conflict_median_strip', disabled: 'conflict_median_strip', type: 'select',labelName: '競合前共通道路分離帯有無', 'SELECTER': setSelecter(codeMaster, 'common_yesnone_kana'), 'SELECT_KEY': 'common_yesnone_kana'},
                {key: 34, keyName: 'count_left_right_turns', disabled: 'count_left_right_turns', type: 'select',labelName: '右左折計', 'SELECTER': setSelecter(codeMaster, 'count_left_right_turns'), 'SELECT_KEY': 'count_left_right_turns'},
                {key: 35, keyName: 'count_straight', disabled: 'count_straight', type: 'select',labelName: '直進計', 'SELECTER': setSelecter(codeMaster, 'count_straight'), 'SELECT_KEY': 'count_straight'},
            ]
        }
        return [
            {key: 1, keyName: 'location_conflict_type', disabled: '', type: 'location_conflict_type', labelName: '競合対象'},
            {key: 2, keyName: 'include_commercial_area', disabled: '', type: 'select', labelName: '徒歩5分商圏内外', 'SELECTER': setSelecter(codeMaster, 'include_commercial_area'), 'SELECT_KEY': 'include_commercial_area'},
            {key: 3, keyName: 'bland_name', disabled: '', type: 'label', labelName: 'チェーン名'},
            {key: 4, keyName: 'tenpo_name', disabled: '', type: 'text', labelName: '店名'},
            {key: 5, keyName: 'business_hours', disabled: '', type: 'select', labelName: '営業時間', 'SELECTER': setSelecter(codeMaster, 'business_hours'), 'SELECT_KEY': 'business_hours'},
            {key: 6, keyName: 'closing_time', disabled: 'closing', type: 'time-label', labelName: '閉店時間'},
            {key: 7, keyName: 'sales_floor_area_size', disabled: '', type: 'text', labelName: '売場面積(㎡)'},
            {key: 8, keyName: 'handle_category_parking', disabled: '', type: 'select', labelName: '駐車場有無', 'SELECTER': setSelecter(codeMaster, 'common_yesnone_kana'), 'SELECT_KEY': 'common_yesnone_kana'},
            {key: 9, keyName: 'parking_area_size', disabled: 'parking', type: 'text', labelName: '駐車場面積(㎡)'},
            {key: 10, keyName: 'direct_distance', disabled: '', type: 'round', labelName: '直線距離'},
            {key: 11, keyName: 'road_distance', disabled: '', type: 'round', labelName: '道なり距離'},
            {key: 12, keyName: 'plan_area_shared_road', disabled: 'plan_area_shared_road', type: 'select', labelName: '候補前共通道路', 'SELECTER': setSelecter(codeMaster, 'common__shared_road'), 'SELECT_KEY': 'common__shared_road'},
            {key: 13, keyName: 'plan_area_direction', disabled: 'plan_area_direction', type: 'select', labelName: '方向', 'SELECTER': setSelecter(codeMaster, 'plan_area_direction'), 'SELECT_KEY': 'plan_area_direction'},
            {key: 14, keyName: 'conflict_shared_road', disabled: 'conflict_shared_road', type: 'select', labelName: '競合前共通道路', 'SELECTER': setSelecter(codeMaster, 'common__shared_road'), 'SELECT_KEY': 'common__shared_road'},
            {key: 15, keyName: 'conflict_median_strip', disabled: 'conflict_median_strip', type: 'select', labelName: '競合前共通道路分離帯有無', 'SELECTER': setSelecter(codeMaster, 'common_yesnone_kana'), 'SELECT_KEY': 'common_yesnone_kana'},
            {key: 16, keyName: 'count_left_right_turns', disabled: 'count_left_right_turns', type: 'select', labelName: '右左折計', 'SELECTER': setSelecter(codeMaster, 'count_left_right_turns'), 'SELECT_KEY': 'count_left_right_turns'},
            {key: 17, keyName: 'count_straight', disabled: 'count_straight', type: 'select', labelName: '直進計', 'SELECTER': setSelecter(codeMaster, 'count_straight'), 'SELECT_KEY': 'count_straight'},
        ]

    }
    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster")
        if (res) {
            const d = JSON.parse(res)
            setCodeMaster(d)
        }
        
        document.getElementById("outerContentRef"+props.type)?.addEventListener("scroll", outerContentRefFunc);
        document.getElementById("innerContentRef"+props.type)?.addEventListener("scroll", innerContentRefFunc);
    }, [])

    React.useEffect(() => {
        if (props.locationConflict) {
            setConflictData(props.locationConflict)
        }
    }, [props.locationConflict])

    React.useEffect(() => {
        if (props.ownStore) {
            setStoreData(props.ownStore)
        }
    }, [props.ownStore])

    const clickHandleEditStore = () => {
        setEditStore(true)
        setEditStoreDisabled(false)
        const copyData = JSON.parse(JSON.stringify(storeData))
        setOriginStoreData(copyData)
    }

    const cancelEditStore = () => {
        setEditStore(false)
        setEditStoreDisabled(true)
        setStoreData(originStoreData)
    }

    const registEditStore = () => {
        setEditStore(false)
        setEditStoreDisabled(true)
        updateRegist([storeData])
    }

    const clickHandleEditConflict = () => {
        // 更新フラグ
        dispatch(setUnitEdit(true));
        setEditConflict(true)
        setEditDisabled(false)
        const copyData = JSON.parse(JSON.stringify(conflictData))
        setOriginConflictData(copyData)
    }

    const cancelEditConflict = () => {
        dispatch(setUnitEdit(false));
        setEditConflict(false)
        setEditDisabled(true)
        setConflictData(originConflictData)
    }

    const registEditConflict = () => {
        // バリデーションチェック
        const response = validationCheck(conflictData)
        if (response && response.length > 0) {
            let tempMessage = ""
            response.map((row: any) => {
                tempMessage +=  row
            })
            dispatch(setUnitEdit(false));
            setMessage(tempMessage)
            setMessageDialogOpen(true)
            setHasButton(true)
        } else {
            setEditConflict(false)
            setEditDisabled(true)
            updateRegist(conflictData)    
        }
    }

    const updateRegist = (data: any) => {
        const params = {
            mode: "regist",
            endPoint: "/location/Conflict/v1/update",
            query: {
                planAreaId: objLongPageParam.planAreaId,
                data: data
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
        try {
            await apiRequest(params);
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            props.setReload(true)
        } catch (e) {
            console.log(e);
            if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
            }
        }
        })();
    }

    const storeDataVal = (key: string) => {
        let res = ""
        if (storeData !== undefined) {
            res = storeData[key]
        }
        return res
    }

    const handleChange=(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,data: any, labelName:string)=>{
        if (labelName === 'gondola_scale') {
            if (isNaN(Number(e.target.value))) {
                return
            }
        }
        data[labelName] = e.target.value
        setDraw(!draw)
    };

    const handleSelectedChange = (e: SelectChangeEvent<string>, data: any, labelName: string) => {
        data[labelName] = e.target.value
        if (labelName === 'location') {
            const side_road_type = ["0", "1", '2', "3"]
            data['side_road_type'] = side_road_type[data[labelName]]
        }
        setDraw(!draw)
    }

    const checkEditDisabled = (data: any, columnDisabled: string) => {
        let disabled = false
        if (editDisabled) {
            disabled = true
        } else {
            if (columnDisabled !== '') {
                disabled = checkDisabled(data, columnDisabled)
            }    
        }
        return disabled
    }

    const storeValue = (key: string) => {
        let resultValue = ""
        if (storeData && objDonation && objStoreOpeningConditions) {
            switch (key) {
                case 'bland_name':
                    resultValue = storeData.bland_name
                    break;
                case 'business_hours':
                    resultValue = '24H以外'
                    if (objStoreOpeningConditions.open24Hours === '12') {
                        resultValue = '24H'
                    }
                    break;
                case 'handle_category_liquor':
                    resultValue = 'なし'
                    if (objStoreOpeningConditions.alcoholLicense === '12') {
                        resultValue = 'あり'
                    }
                    break;
                case 'handle_category_tobacco':
                    resultValue = 'なし'
                    if (objStoreOpeningConditions.tobaccoLicence === '12') {
    
                        resultValue = 'あり'
                    }
                    break;
                case 'handle_category_tobacco':
                    resultValue = objStoreOpeningConditions.salsesArea.toString()
                    break;
                case 'sales_floor_area_size':
                    resultValue = objStoreOpeningConditions.salsesArea.toString()
                    break;
                case 'handle_category_parking':
                    const parkingArea = objDonation.parkingArea === "" ? 0 : Number(objDonation.parkingArea) 
                    resultValue = parkingArea > 0 ? 'あり' : 'なし'
                    break;
                case 'store_frontage':
                    resultValue = objStoreOpeningConditions.storefrontage.toString()
                    break;
                case 'parking_area_size':
                    resultValue = objDonation.parkingArea.toString()
                    break;
                case 'number_of_parking':
                    resultValue = objDonation.parkingCapacity.toString()
                    break;
                case 'front_parking_tangent':
                    resultValue = objDonation.parkingTangentLineFrontage.toString()
                    break;
                case 'front_first_entrance':
                    resultValue = objDonation.no1EntranceFrontage.toString()
                    break;
                case 'front_first_depth':
                    resultValue = objDonation.no1DepthFrontage.toString()
                    break;
                case 'front_second_entrance':
                    resultValue = objDonation.no2EntranceFrontage.toString()
                    break;
                case 'front_second_depth':
                    resultValue = objDonation.no2DepthFrontage.toString()
                    break;
                case 'side_parking_tangent':
                    if (storeData['side_road_type'] === "0") {
                        resultValue = objDonation.parkingTangentLineSideWayA.toString()
                    } else if (storeData['side_road_type'] === "1") {
                        resultValue = objDonation.parkingTangentLineSideWayB.toString()
                    } else if (storeData['side_road_type'] === "3") {
                        resultValue = objDonation.parkingTangentLineBackstreet.toString()
                    }
                    break;
                case 'side_first_entrance':
                    if (storeData['side_road_type'] === "0") {
                        resultValue = objDonation.no1EntranceSideWayA.toString()
                    } else if (storeData['side_road_type'] === "1") {
                        resultValue = objDonation.no1EntranceSideWayB.toString()
                    } else if (storeData['side_road_type'] === "3") {
                        resultValue = objDonation.no1EntranceBackstreet.toString()
                    }
                    break;
                case 'side_first_depth':
                    if (storeData['side_road_type'] === "0") {
                        resultValue = objDonation.no1DepthSideWayA.toString()
                    } else if (storeData['side_road_type'] === "1") {
                        resultValue = objDonation.no1DepthSideWayB.toString()
                    } else if (storeData['side_road_type'] === "3") {
                        resultValue = objDonation.no1DepthBackstreet.toString()
                    }
                    break;
                case 'side_second_entrance':
                    if (storeData['side_road_type'] === "0") {
                        resultValue = objDonation.no2EntranceSideWayA.toString()
                    } else if (storeData['side_road_type'] === "1") {
                        resultValue = objDonation.no2EntranceSideWayB.toString()
                    } else if (storeData['side_road_type'] === "3") {
                        resultValue = objDonation.no2EntranceBackstreet.toString()
                    }
                    break;
                case 'side_second_depth':
                    if (storeData['side_road_type'] === "0") {
                        resultValue = objDonation.no2DepthSideWayA.toString()
                    } else if (storeData['side_road_type'] === "1") {
                        resultValue = objDonation.no2DepthSideWayB.toString()
                    } else if (storeData['side_road_type'] === "3") {
                        resultValue = objDonation.no2DepthBackstreet.toString()
                    }
                    break;
                default:
                    break;
            }
        }

        return resultValue
    }

    // スクロールイベント
    const outerContentRefFunc = () => {
        const outerContentRef = document.getElementById('outerContentRef'+props.type);
        const innerContentRef = document.getElementById('innerContentRef'+props.type);
        if (outerContentRef && innerContentRef) {
            innerContentRef.scrollLeft = outerContentRef.scrollLeft
        }
    }
    const innerContentRefFunc = () => {
        const outerContentRef = document.getElementById('outerContentRef'+props.type);
        const innerContentRef = document.getElementById('innerContentRef'+props.type);
        if (innerContentRef && outerContentRef) {
            outerContentRef.scrollLeft = innerContentRef.scrollLeft
        }
    }

    const [commentOpen, setCommentOpen] = React.useState<boolean>(false)
    const [text, setText] = React.useState<string>("")

    const storeCommentOpen = () => {
        setText("conflict_stores") //競合自店
        setCommentOpen(true)
    }

    const clickCommentOpen = () => {
        let text = "conflict_s_m" //競合SM
        if (props.type === CVS_MASTER_DATA) {
            text = "conflict_store" //競合店
        }
        setText(text)
        setCommentOpen(true)
    }

    const handleSaveComment = () => {
        setCommentOpen(false)
    }


    const displayLocationConflictType = (data: any, key: any) => {
        let res = ""
        res = hasConflictSubject(data, data, props.targetRadius, props.type, codeMaster)
        const value = setSelecterValue(codeMaster, 'location_conflict_type', res)
        data.location_conflict_type = value

        return res
    }

    const handleSelectedClosingTime = (e: SelectChangeEvent<string>, key: string, data: any, labelName: string) => {
        let hours = data[labelName].slice(0, 2)
        let minutes = data[labelName].slice(2, 4)
        if (key === "hours") {
            data[labelName] = e.target.value + minutes
        } else {
            data[labelName] = hours + e.target.value
        }
        setDraw(!draw)
    }

    const selectClosingTime = (date: any, key: string) => {
        const result = closingTime(date)
        if (key === "hours") {
            return result.hours
        }
        return result.minutes
    }

    return (
        <Box sx={{ width: '100%', border: '1px solid black', display: 'flex', justifyContent: 'start' }}>
            <MessageDialog props={{
                open:messageDialogOpen,
                handleModal:closeMessageDialog,
                body: message,
                hasButton:hasButton,
                buttonBody:LOCAL_CONSTANT.LABEL.CLOSE,
            }}
            />
            <Comment props={{
                open: commentOpen,
                params: {
                    table_name: "location_conflict",
                    column_name: text,
                    comment: "",
                },
                handleModal: setCommentOpen,
                handleSaveComment: handleSaveComment
            }} />
            <Box sx={{ width: '380px', margin: '4px' }}>
                <Box sx={{ minWidth: '100%', width: '100%', height: '90px', padding: '10px', display: 'flex', justifyContent: 'end'}}>
                    <Button
                        onClick={storeCommentOpen}
                        disabled={setPermissionFunc(props.userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.CONFLICT,'own_deficiency_comment_button')}
                    ><ChatBubbleIcon />
                    </Button>
                    <Box sx={{ display: props.type === SM_DRUG_MASTER_DATA ? 'none' : 'flex' }}>
                        <Button
                            onClick={clickHandleEditStore}
                            disabled={setPermissionFunc(props.userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.CONFLICT,'own_edit_button')}
                            sx={{ display: editStore === false ? 'block' : 'none' }}
                        ><EditIcon/>
                        </Button>
                        <Button
                            onClick={cancelEditStore}
                            variant="contained" color="error" size="small"
                            sx={{ display: editStore === false ? 'none' : 'block', margin: '14px 10px' }}
                        >{LOCAL_CONSTANT.LABEL.CANCEL}
                        </Button>
                        <Button
                            onClick={registEditStore}
                            variant="contained" color="success" size="small"
                            sx={{ display: editStore === false ? 'none' : 'block', margin: '14px 10px' }}
                        >{LOCAL_CONSTANT.LABEL.SAVE}
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ minWidth: '100%', width: '100%', textAlign: 'center', border: '1px solid black' }}>
                    {
                    CVSLabel().map((row: any) => (
                        <Box key={row.key}>
                        {
                            row.cols === 0 &&
                            <Box sx={{ display: 'flex'}} key={row.key+'-'+row.cols}>
                                <Typography sx={{ width: '200px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', backgroundColor: '#D9D9D9', fontSize: row.fontSize }}>{row.labelName}</Typography>
                                <Typography sx={{ width: '180px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', backgroundColor: '#D9D9D9', color: 'black'}}>{row.keyName}</Typography>
                            </Box>
                        }
                        {
                            row.cols === 1 &&
                            <Box sx={{ display: 'flex'}} key={row.key+'-'+row.cols}>
                                {
                                    row.requireStatus === 0 && 
                                    <Typography sx={{ width: '200px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', fontSize: row.fontSize }}>
                                        {row.labelName}
                                    </Typography>
                                }
                                {
                                    row.requireStatus === 1 && 
                                    <Typography sx={{ width: '200px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', fontSize: row.fontSize }}>
                                        {row.labelName}
                                        <span style={{ color: 'red', fontSize: '10px' }}>＊</span>
                                    </Typography>
                                }
                                {
                                    row.requireStatus === 2 && 
                                    <Typography sx={{ width: '200px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', fontSize: row.fontSize }}>
                                        {row.labelName}
                                        <span style={{ color: 'red', fontSize: '10px' }}>＊＊</span>
                                    </Typography>
                                }
                                {
                                    row.target === 'gray-data' &&
                                    <Typography sx={{ minWidth: '180px', width: '180px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                        {row.value}
                                    </Typography>
                                }
                                {
                                    row.target === 'data' &&
                                    <Typography sx={{ minWidth: '180px', width: '180px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {row.value}
                                    </Typography>
                                }
                                {
                                    storeData !== undefined &&
                                    row.target === 'db-value' &&
                                    <Typography sx={{ minWidth: '180px', width: '180px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                                    >
                                    {setSelecterDisplay(codeMaster, row.keyName, storeDataVal(row.keyName))}
                                    </Typography>
                                }
                                {
                                    editStore === false &&
                                    storeData !== undefined &&
                                    row.target === 'db-text' &&
                                    <Typography sx={{ minWidth: '180px', width: '180px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                    {storeDataVal(row.keyName)}</Typography>
                                }
                                {
                                    editStore === true &&
                                    row.target === 'db-text' &&
                                    <TextField
                                        disabled={editStoreDisabled}
                                        sx={{ minHeight: '50px', height: '50px', minWidth: '180px', width: '180px', border: '1px solid black', backgroundColor: '#FFFCD6',
                                        '& input': { padding: '14px 0' }
                                        }}
                                        onChange={(e) => {handleChange(e, storeData, row.keyName)}}
                                        value={storeDataVal(row.keyName)}
                                    ></TextField>
                                }
                                {
                                    editStore === false &&
                                    storeData !== undefined &&
                                    row.target === 'db-select' &&
                                    <Typography sx={{ width: '180px', minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                    {setSelecterDisplay(codeMaster, row.SELECT_KEY, storeData[row.keyName])}
                                    </Typography>
                                }
                                {
                                    editStore === true &&
                                    row.target === 'db-select' &&
                                    <FormControl>
                                    <Select
                                        onChange={ (e) => handleSelectedChange(e, storeData, row.keyName) } 
                                        sx={{ minHeight: '50px', height: '50px', minWidth: '180px', width: '180px',border: '1px solid black' , backgroundColor: '#FFFCD6'}}
                                        value={ storeData[row.keyName] }
                                    >
                                    {
                                        row.SELECTER?.map((row: any) => (
                                            <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                                        ))
                                    }
                                    </Select>
                                    </FormControl>
                                }
                            </Box>
                        }
                        {
                            row.cols === 2 &&
                            <Box sx={{ display: 'flex'}} key={row.key+'-'+row.cols}>
                                <Box sx={{ width: '200px', display: 'flex' }}>
                                    <Typography sx={{ width: '20%', padding: '10px 6px', border: '1px solid black',
                                        writingMode: 'vertical-lr',
                                    }}>{row.labelName}</Typography>
                                    <Box sx={{ width: '80%' }}>
                                    {row.colsLabel.map((label: any) => (
                                        (label.requireStatus === 0) ? (
                                            <Typography sx={{ minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', fontSize: row.fontSize }} key={row.key+'-'+row.cols+'-'+label.key}>
                                                {label.labelName}
                                            </Typography>
                                        ) : (label.requireStatus === 1) ? (
                                            <Typography sx={{ minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', fontSize: row.fontSize }} key={row.key+'-'+row.cols+'-'+label.key}>
                                                {label.labelName}
                                                <span style={{ color: 'red', fontSize: '10px' }}>＊</span>
                                            </Typography>
                                        ) : (
                                            <Typography sx={{ minHeight: '50px', height: '50px', padding: '10px 0', border: '1px solid black', fontSize: row.fontSize }} key={row.key+'-'+row.cols+'-'+label.key}>
                                                {label.labelName}
                                                <span style={{ color: 'red', fontSize: '10px' }}>＊＊</span>
                                            </Typography>
                                        )
                                    ))}
                                    </Box>
                                </Box>
                                <Box sx={{ width: '180px' }}>
                                {row.colsLabel.map((label: any) => (
                                    <Box sx={{ width: '100%', display: 'flex'}} key={row.key+'-'+row.cols+'-'+label.key}>
                                        <Typography sx={{ minHeight: '50px', height: '50px', width: '100%', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                        {label.value}
                                        </Typography>
                                    </Box>
                                ))}
                                </Box>
                            </Box>
                        }
                        </Box>
                    ))
                    }
                </Box>
            </Box>
            <Box sx={{ width: '66%', margin: '0 4px' }}>
                <Box sx={{ width: '100%', height: '76px', padding: '10px', display: 'flex', justifyContent: 'end'}}>
                    <Button
                        onClick={clickCommentOpen}
                        disabled={setPermissionFunc(props.userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.CONFLICT,'conflict_deficiency_comment_button')}
                    ><ChatBubbleIcon />
                    </Button>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            onClick={clickHandleEditConflict}
                            disabled={setPermissionFunc(props.userType,objLocationBase.task_id,LOCAL_CONSTANT.NAVI.CONFLICT,'conflict_edit_button')}
                            sx={{ display: editConflict === false ? 'block' : 'none' }}
                        ><EditIcon/>
                        </Button>
                        <Button
                            onClick={cancelEditConflict}
                            variant="contained" color="error" size="small"
                            sx={{ display: editConflict === false ? 'none' : 'block', margin: '10px' }}
                        >{LOCAL_CONSTANT.LABEL.CANCEL}
                        </Button>
                        <Button
                            onClick={registEditConflict}
                            variant="contained" color="success" size="small"
                            sx={{ display: editConflict === false ? 'none' : 'block', margin: '10px' }}
                        >{LOCAL_CONSTANT.LABEL.SAVE}
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', overflowX: 'scroll', overflowY: 'clip' }} id={"outerContentRef"+props.type}>
                    <Box sx={{ display: 'flex'}}>
                        {header().map((row: any) => (
                            <Box key={row.key}>
                            <Typography sx={{ minWidth: '240px', width: '240px', padding: '1px 0', fontSize: '0.01rem' }}>
                            {row.label}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ width: '100%', overflowX: 'scroll', overflowY: 'clip', textAlign: 'center' }} id={"innerContentRef"+props.type}>
                    <Box>
                        <Box sx={{ display: 'flex' }}>
                            {header().map((row: any) => (
                                <Box key={row.key}>
                                <Typography sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: '#D9D9D9', color: 'black'}}>
                                {row.label}</Typography>
                                </Box>
                            ))}
                        </Box>
                        <Box>
                            {columnList().map((column: any) => (
                                <Box sx={{ display: 'flex'}} key={column.key}>
                                {conflictData.map((conflict: any, conflictIndex: any) => (
                                    <Box key={conflictIndex+'-'+column.key+'-'+column.keyName+'-'+conflict.conflict_sequence}>
                                    {
                                        /** 競合店データを表示 */
                                    }
                                    {
                                        column.type === 'codemaster-value' &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                                        >{setSelecterDisplay(codeMaster, column.keyName, conflict[column.keyName])}</Typography>
                                    }
                                    {
                                        column.type === 'label' &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}

                                        >{conflict[column.keyName]}</Typography>
                                    }
                                    {
                                        editConflict === false &&
                                        column.type === 'round' &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}

                                        >{Math.round(conflict[column.keyName] * 100) / 100}</Typography>
                                    }
                                    {
                                        editConflict === true &&
                                        column.type === 'round' &&
                                        <TextField
                                            disabled={checkEditDisabled(conflict, column.disabled)}
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', border: '1px solid black',
                                            backgroundColor: checkEditDisabled(conflict, column.disabled) === true
                                            ? '#EEE'
                                            : '#FFFCD6',
                                            '& input': { padding: '14px 0' }
                                            }}
                                            onChange={(e) => {handleChange(e, conflict, column.keyName)}}
                                            value={conflict[column.keyName]}
                                        ></TextField>
                                    }
                                    {
                                        column.type === 'location_conflict_type' &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}

                                        >{displayLocationConflictType(conflict, column.keyName)}</Typography>
                                    }
                                    {
                                        editConflict === false &&
                                        column.type === 'text' &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                                        >{conflict[column.keyName]}</Typography>
                                    }
                                    {
                                        editConflict === true &&
                                        column.type === 'text' &&
                                        <TextField
                                            disabled={checkEditDisabled(conflict, column.disabled)}
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', border: '1px solid black',
                                            backgroundColor: checkEditDisabled(conflict, column.disabled) === true
                                            ? '#EEE'
                                            : '#FFFCD6',
                                            '& input': { padding: '14px 0' }
                                            }}
                                            onChange={(e) => {handleChange(e, conflict, column.keyName)}}
                                            value={conflict[column.keyName]}
                                        ></TextField>
                                    }
                                    {
                                        editConflict === false &&
                                        column.type === 'time-label' &&
                                        conflict['business_hours'] === "0" &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                                        >{displayClosingTime(conflict[column.keyName], true)}</Typography>
                                    }
                                    {
                                        editConflict === false &&
                                        column.type === 'time-label' &&
                                        conflict['business_hours'] === "1" &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                                        ></Typography>
                                    }
                                    {
                                        editConflict === true &&
                                        column.type === 'time-label' &&
                                        <FormControl disabled={checkEditDisabled(conflict, column.disabled)}>
                                            <Box sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px' }}>
                                                <Select
                                                    sx={{width: '50%', border: '1px solid black',
                                                    backgroundColor: checkEditDisabled(conflict, column.disabled) === true
                                                    ? '#EEEEEE'
                                                    : '#FFFCD6',
                                                    }}
                                                    disabled={conflict['business_hours'] === '1' ? true : false }
                                                    onChange={ (e) => handleSelectedClosingTime(e, "hours", conflict, column.keyName) }
                                                    value={ selectClosingTime(conflict[column.keyName], "hours") }
                                                >
                                                {hours.map((row: any) => (
                                                    <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                                                ))}
                                                </Select>
                                                <Select
                                                    sx={{width: '50%', border: '1px solid black',
                                                    backgroundColor: checkEditDisabled(conflict, column.disabled) === true
                                                    ? '#EEEEEE'
                                                    : '#FFFCD6',
                                                    }}
                                                    disabled={conflict['business_hours'] === '1' ? true : false }
                                                    onChange={ (e) => handleSelectedClosingTime(e, "minutes", conflict, column.keyName) }
                                                    value={ selectClosingTime(conflict[column.keyName], "minutes") }
                                                >
                                                {minutes.map((row: any) => (
                                                    <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                                                ))}
                                                </Select>
                                            </Box>
                                        </FormControl>
                                    }
                                    {
                                        /** SELECT */
                                    }
                                    {
                                        editConflict === false &&
                                        column.type === 'select' &&
                                        <Typography
                                            sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px', padding: '10px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                                        >{setSelecterDisplay(codeMaster, column.SELECT_KEY, conflict[column.keyName] )}
                                        </Typography>
                                    }
                                    {
                                        editConflict === true &&
                                        column.type === 'select' &&
                                        <FormControl disabled={checkEditDisabled(conflict, column.disabled)}>
                                            <Select
                                                onChange={ (e) => handleSelectedChange(e, conflict, column.keyName) } 
                                                sx={{ minHeight: '50px', height: '50px', minWidth: '240px', width: '240px',
                                                border: '1px solid black',
                                                backgroundColor: checkEditDisabled(conflict, column.disabled) === true
                                                ? '#EEEEEE'
                                                : '#FFFCD6',
                                                }}
                                                value={ conflict[column.keyName] }
                                            >
                                            {
                                                column.SELECTER?.map((row: any) => (
                                                    <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                                                ))
                                            }
                                            </Select>
                                        </FormControl>
                                    }
                                    </Box>
                                ))}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ConflictList;
