import { createSlice } from '@reduxjs/toolkit';

//Sliceの作成
export const conflictSlice = createSlice({
    name: 'conflict',
    initialState: {
        conflictObj: [],
        actualCommercialArea: {
            numOfHouseholds_5minuteWalkFromCommercialArea: "",
            households: "0",
            numOfHouseholds_ActualCommercialArea: "",
            numOfEmployees_5minuteWalkFromCommercialArea: "",
            employees: "0",
            numOfEmployees_ActualCommercialArea: "",
        },
        market_cut: {
            plan_area_id: "",
            branch: "",
            create_date: "",
            update_date: "",
            delete_flag: "",
            households: "",
            employees: "",
            excel_path: "",
            market_cut_img_path: "",
            market_cut_basis_img_path: "",
        }
    },

    //toolKitを利用した値変更処理
    reducers: {
        setConflict: (state, action) => {
            state.conflictObj = action.payload;
        },
        setActualCommercialArea: (state, action) => {
            state.actualCommercialArea = action.payload;
        },
        setMarketCut: (state, action) => {
            state.market_cut = action.payload;
        },
    }
});

export const {
    setConflict,
    setActualCommercialArea,
    setMarketCut
} = conflictSlice.actions;
export default conflictSlice.reducer;