import * as React from 'react';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { apiRequest } from '../../../api/ApiCall';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LOCAL_CONSTANT } from '../../Const';
import MessageDialog from '../../components/MessageDialog';
import { DeleteConfirmationModal } from '../../ResearchReport/DeleteConfirmationModal';
import { setOtherDocumentExcelPath } from '../../../features/longPageSlice';
import {
  deleteUploadFile,
  getFileMetadata,
  uploadFile,
  getFileData
} from '../../../utility/storage';
import { tokenLogin } from '../../../utility/firebase';
import { useUtilityContext } from '../../../utility-provider';
import {
  uploadFileJsonPathFunc,
  upsertLocationBaseFileParam,
  upsertLocationBaseDeleteFileParam
} from '../../components/LocationBaseFunc';
import { useUserContext } from '../../../user-provider';


interface OtherExcelProps {
  props: {
  }
};

const headerCell = {
  backgroundColor: '#D9D9D9',
  fontSize: '12px',
  color: 'black',
  borderBottom: '1px solid black',
  fontWeight: 'Bold'
};

const OtherExcel: React.FC<OtherExcelProps> = ({ props }) => {

  //候補地NOと枝を取得
  const objLongPageParam = useAppSelector((state) => state.longPage.param);
  //調査書基本を取得
  const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
  const dispatch = useAppDispatch();

  const utilityCtx = useUtilityContext();

  //ファイルアップロード
  const [message, setMessage] = useState<string>("");
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
  const [hasButton, setHasButton] = useState<boolean>(false);

  //ユーザーIDを取得
  const [userId, setUserId] = useState<string>("");
  const user = useUserContext();

  //モーダル開閉フラグ(削除確認)
  const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
  const [path, setPath] = useState<string>("");
	const [fileName, setFileName] = useState<string>("");
  const [date, setDate] = useState<string>("");

  const handleDeleteConfModalOpen = (pathParam:string, nameParam:string, dateParam:string) => {
    setDeleteConfModalOpen(true);
    setPath(pathParam);
		setFileName(nameParam);
    setDate(dateParam);
  }

  const closeMessageDialog = () => {
    setMessage("");
    setHasButton(false);
    setMessageDialogOpen(false);
  };

  const handleClickFile = () => {
    const fileElem = document.getElementById("otherExcelFileElem");
    if (fileElem) {
      fileElem.click();
    }
  };

  const handleChangeFile = (newFile: any) => {
    fileUpload(newFile.target.files[0]);
    newFile.target.value = '';
  };

  //ファイル保存
  const fileDownload = (fileName: string) => {
    const filePath = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + fileName;
    (async () => {
      try {
        const url = await getFileData(filePath);
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = fileName;
          link.href = window.URL.createObjectURL(blob);
          link.click();
          document.body.removeChild(link);
        };
        xhr.open('GET', url as string);
        xhr.send();
      }catch(e){
        console.log(e);
      }
    })();
  }

  //ファイルアップロード
  const fileUpload = (file: any) => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner()
    }
    (async () => {
      try {
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_FILE);
        setMessageDialogOpen(true);
        await uploadFile(file, objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + file.name);
        const uploadResultFile = await getFileMetadata(objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + file.name);
        const newUploadFile = uploadFileJsonPathFunc(uploadResultFile, userId);
        //DB保存
        updateRegist(upsertLocationBaseFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, newUploadFile, 'other_document_excel_path', userId, file.name));
        //Redux保存
        dispatch(setOtherDocumentExcelPath([...objLocationBase.other_document_excel_path, newUploadFile]));
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_FILE);
        setHasButton(true);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_FILE);
        setHasButton(true);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  };

  //ファイル削除
  const handleExcelDeleteChange = () => {
    const newListUploadFile = objLocationBase.other_document_excel_path.filter(row => row.file_path !== path && row.upload_date !== date) //選択したpathとupload_date以外を返す
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE);
        setMessageDialogOpen(true);
        //CloudStorageから削除対象のファイル情報を取得
        const deleteFile = await getFileMetadata('/'+objLongPageParam.planAreaId+'/'+objLongPageParam.branch+path);
        await deleteUploadFile(deleteFile, '/'+objLongPageParam.planAreaId+'/'+objLongPageParam.branch+path);
        //DB保存
        updateRegist(upsertLocationBaseDeleteFileParam(objLocationBase, objLongPageParam.planAreaId, objLongPageParam.branch, newListUploadFile, 'other_document_excel_path', fileName));
        //Redux保存
        dispatch(setOtherDocumentExcelPath([...newListUploadFile]));
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE)
        setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE)
        setHasButton(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  };

  const updateRegist = (params: any) => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }


  //ユーザーID取得
  useEffect(() => {
    const sync = async () => {
      await tokenLogin(window.location.href)
        .then((val) => {
          if (val === 'unprocessed') {
            // 未処理の場合はバックドロップ消さない
            return;
          }
          if (user.setUserData) {
            user.setUserData(val as string);
          }
        }).catch((e) => {
          console.log(e);
        })
    }
    sync();
    if (user.userData !== '') {
      setUserId(JSON.parse(user.userData || 'null').staff_number);
    }
  }, [user]);

  return (
    <Box>

      <Box>
        <MessageDialog props={{
          open: messageDialogOpen,
          handleModal: closeMessageDialog,
          body: message,
          hasButton: hasButton,
          buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
        }}
        />
        <input
          id="otherExcelFileElem"
          type="file"
          style={{ display: 'none' }}
          onChange={handleChangeFile}
          accept=".xlsx"
        />
        <Button
          sx={{ margin: '20px 0px 5px 0px' }}
          variant="contained"
          color="success"
          size="small"
          onClick={handleClickFile}
        >その他資料excel
        </Button>
        <Typography sx={{color: "red", marginBottom: "10px"}}>RFCのアップロードする資料は3年間推移のみとなります</Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ paddingRight: "40px" }}>
          <TableContainer sx={{ height: "424px", width: "100%", border: '1px solid black' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                  <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                  <TableCell sx={headerCell}></TableCell>
                  <TableCell sx={headerCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objLocationBase.other_document_excel_path.map((data, index) => (
                  <TableRow className="tableRow" key={index}>
                    <TableCell>{data.file_name}</TableCell>
                    <TableCell>{data.upload_date}</TableCell>
                    <TableCell>{data.upload_staff_number}</TableCell>
                    <TableCell>
                      <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(data.file_name)}><DownloadIcon /></IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton sx={{ color: 'black' }} onClick={() => handleDeleteConfModalOpen(data.file_path, data.file_name, data.upload_date)}><DeleteIcon /></IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* 削除確認モーダル */}
      <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleExcelDeleteChange} />

    </Box>
  )

}

export default OtherExcel;