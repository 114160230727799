import * as React from 'react';

import { GeoJsonLine } from '../../../utility/turf';
import {
  zIndexOrder,
} from "../Interface";

export const drawImpactCounter = (
    impactCounterList: any,
) => {
    const tmpMarker:any[] = [];
    impactCounterList.forEach((data: any, index: any) => {
        try{
          const arrowPoly = setPolyLine(data, 'impact');
          // if (arrowPoly) {
          //   arrowPoly.setMap(map)
          // }
          const dummy = setPolyLine(data, 'dummy');
          // if (dummy) {
          //   dummy.setMap(map)
          // }
          tmpMarker.push({
            key: data.uuid,
            data: data,
            arrowPoly: arrowPoly,
            dummy: dummy,
          })

        } catch (error) {
            console.log("------------------------")
            console.log("表示エラー")
            console.log(error)
            console.log(data)
            console.log("------------------------")
        }
    })

    // // 前回表示分のリスト取得
    // const  diffBefore: any[] = []
    // icList?.forEach((item: any) => {
    //     diffBefore.push(item.data)
    // })
    // // 前回表示分のリストとの差分取得
    // const diff = diffBefore.filter(x => !impactCounterList.includes(x))

    // setIcList(tmpMarker);

    // // 前回表示分の差分をMAPから表示削除
    // if (diff.length > 0) {
    //     diffClear(diff, icList)
    // }

    return tmpMarker
}


export const drawImpactCounterSummary = (
  impactCounterList: any,
  selectTab: string,
) => {
  const tmpMarker:any[] = [];
  const tmpCounter:any = {};
  let arrowPoly:any = null;
  let dummy:any = null;
  
  impactCounterList.forEach((data: any, index: any) => {
      try{
        if (tmpCounter[data.uuid] === undefined) {
          let tmpCounterList: any = [];
          // 車ルート
          if ((selectTab === '0' || selectTab === '1') &&
            (data.counter_type === '0' || data.counter_type === '1')
          ) {
            tmpCounterList.push(data)
          } else if ((selectTab === '2' || selectTab === '3') &&
            (data.counter_type === '1' || data.counter_type === '2')
          ) {
            tmpCounterList.push(data)
          } else {
            tmpCounterList.push(data)
          }
          if (tmpCounterList.length > 0) {
            arrowPoly = setPolyLine(data, 'impact');
            arrowPoly.setOptions({
              clickable: true
            })
            dummy = setPolyLine(data, 'dummy');
            dummy.setOptions({
              clickable: true
            })
            tmpCounter[data.uuid] = {
              key: data.uuid,
              data: data,
              counter: tmpCounterList,
              arrowPoly: arrowPoly,
              dummy: dummy,
            }
          }
        } else {
          let tmpCounterList: any = tmpCounter[data.uuid]['counter'];
          if ((selectTab === '0' || selectTab === '1') &&
            (data.counter_type === '0' || data.counter_type === '1')
          ) {
            tmpCounterList.push(data)
          } else if ((selectTab === '2' || selectTab === '3') &&
            (data.counter_type === '1' || data.counter_type === '2')
          ) {
            tmpCounterList.push(data)
          }
          tmpCounter[data.uuid]['counter'] = tmpCounterList
        }
      } catch (error) {
          console.log("------------------------")
          console.log("表示エラー")
          console.log(error)
          console.log(data)
          console.log("------------------------")
      }
  })


  const entries = Object.entries(tmpCounter)
  for(const [key, row] of entries) {
    tmpMarker.push(row)
  }

  return tmpMarker
}

export const setPolyLine = (data: any, target: string) => {
  const polyLine = JSON.parse(data.shape) as GeoJsonLine
  if (target === 'impact') {
    return new google.maps.Polyline({
      path: polyLine.geometry.coordinates.map((val: any) => {
        return {lat: val[1], lng: val[0]}
      }),
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 4,
      draggable: false,
      clickable: false,
      icons: [
        {
          icon: { 
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 4,
            fillColor: "#FF0000",
            fillOpacity: 1.0,
          },
          offset: "100%",
        },
      ],
      zIndex: zIndexOrder.impactArrow
    })
  } else if (target === 'dummy') {
    return new google.maps.Polyline({
      path: polyLine.geometry.coordinates.map((val: any) => {
        return {lat: val[1], lng: val[0]}
      }),
      geodesic: true,
      strokeColor: "#00FFFF",
      strokeOpacity: 0,
      strokeWeight: 16,
      draggable: false,
      clickable: false,
      zIndex: zIndexOrder.impactDummy
    })
  }

}

/**
 * 前回表示分のmarker、polygonの差分を非表示にする
 * @param diffList 
 * @param list 
 */
const diffClear = (diffList: any, list: any) => {
  list?.forEach(function(item: any){
      diffList?.forEach(function(diffItem: any){
        if (item.key === diffItem.uuid) {
            item.arrowPoly.setMap(null);
            item.dummy.setMap(null);
        }
      })
  });
}

export interface impactCounterProps {
    props:{
    }
  }

const ImpactCounter:React.FC<impactCounterProps> = ({props}) => {

    return (
        <></>
    );
        
}


export default ImpactCounter;
