import * as React from 'react';

import { LOCAL_CONSTANT } from '../../Const';
import {
    Box,
    Typography,
} from '@mui/material';

const commonRatioStyle = { width: '50%', padding: '20px 0', border: '1px solid rgba(0, 0, 0, 0.87)' }

const defaultData = () => {
    return [
        {'key': LOCAL_CONSTANT.LABEL.FRONT_FOR_EXISTING_STORES, common: 0, uncommon: 0},
        {'key': '1', common: 0, uncommon: 0, avg: 0},
        {'key': '2', common: 0, uncommon: 0, avg: 0},
        {'key': '3', common: 0, uncommon: 0, avg: 0},
        {'key': '4', common: 0, uncommon: 0, avg: 0},
        {'key': '5', common: 0, uncommon: 0, avg: 0},
        {'key': '6', common: 0, uncommon: 0, avg: 0}
    ]
}

interface common_ratio_list_interface {
    [key: string]: any
}

const common_ratio_list = {
    '0': {
        'header': LOCAL_CONSTANT.LABEL.CAR+LOCAL_CONSTANT.LABEL.ROUTE+'1',
        'data': JSON.parse(JSON.stringify(defaultData()))
    },
    '1': {
        'header': LOCAL_CONSTANT.LABEL.CAR+LOCAL_CONSTANT.LABEL.ROUTE+'2',
        'data': JSON.parse(JSON.stringify(defaultData()))
    },
    '2': {
        'header': LOCAL_CONSTANT.LABEL.PEOPLE+LOCAL_CONSTANT.LABEL.ROUTE+'1',
        'data': JSON.parse(JSON.stringify(defaultData()))
    },
    '3': {
        'header': LOCAL_CONSTANT.LABEL.PEOPLE+LOCAL_CONSTANT.LABEL.ROUTE+'2',
        'data': JSON.parse(JSON.stringify(defaultData()))
    },
} as common_ratio_list_interface


export interface impactTableProps {
    props:{
        impactData:  any;
    }
}

export const ImpactTable:React.FC<impactTableProps> = ({props}) => {
    const [doFlag, setDoFlag] = React.useState<boolean>(false)
    const [list, setList] = React.useState<boolean>(false)
    const [impactData, setImpactData] = React.useState<any>()
    const [commonRatioList, setCommonRatioList] = React.useState<any>()
    const [commonNumber, setCommonNumber] = React.useState<any>()

    const cahngeHandle = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string, rowKey: string, data: any, dataKey: string) => {
        data[dataKey] = e.target.value
        setDoFlag(!doFlag)
    }

    React.useEffect(() => {
        if (props.impactData) {
            setImpactData(props.impactData)
            const copyData = JSON.parse(JSON.stringify(common_ratio_list))
            setCommonRatioList(copyData)
            const tmp: any = []
            const tmpList: any = []
            JSON.parse(props.impactData.location_impact_common_number).map((element: any) => {
                if (element) {
                    if (!tmp.includes(element.counter_uuid)) {
                        tmp.push(element.counter_uuid)
                        tmpList.push(element)
                    }
                }
                return
            })
            setCommonNumber(tmpList)
        }
    }, [props.impactData])

    React.useEffect(() => {
        if (impactData && commonRatioList) {
            const location_impact_counter = JSON.parse(impactData.location_impact_counter)
            location_impact_counter.map((row: any) => {
                commonUncommon(row)
            })
            setList(true)
        }
    }, [impactData, commonRatioList])

    const commonUncommon = (row: any) => {
        if (commonRatioList) {
            const rowKey = (Number(row.counter_seq) - 1).toString()
            commonRatioList[row.route_seq]['data'][rowKey].common = row.directions_share
            commonRatioList[row.route_seq]['data'][rowKey].uncommon = row.directions_not_share
            commonRatioList[row.route_seq]['data'][rowKey].avg = row.directions_share_ratio
            commonRatioList[row.route_seq]['data'][rowKey]['counter_uuid'] = row.counter_uuid
            setDoFlag(!doFlag)
        }
    }

    const showCommon = (row: any, key: string) => {
        let value = '0'
        if (key === 'avg') {
            value = row[key] > 0 ? (Math.round(row[key] * 10000) / 100).toString()+LOCAL_CONSTANT.LABEL.PERCENT : ''
        } else {
            value = row[key] === 0 ? '' : row[key]
        }
        return value
    }

    const commonRatio = (key: string) => {
        let avg = 0
        let hasF = true
        if (commonRatioList) {
            commonRatioList[key]['data'].map((row: any) => {
                if (row.avg > 0) {
                    if (hasF) {
                        avg = row.avg
                        hasF = false
                    } else {
                        avg = avg * row.avg
                    }
                }
            })    
        }

        return avg !== 0 ? (Math.round(avg * 10000) / 100).toString()+LOCAL_CONSTANT.LABEL.PERCENT : ''
    }

    const displayCommonNumber = (row: any) => {
        let number = ""
        if (row.key === LOCAL_CONSTANT.LABEL.FRONT_FOR_EXISTING_STORES) {
            if (row.common !== 0 || row.uncommon !== 0) {
                number = LOCAL_CONSTANT.LABEL.FRONT_FOR_EXISTING_STORES
            }
        } else {
            commonNumber.map((commonRow: any) => {
                if (commonRow && commonRow.counter_uuid === row.counter_uuid) {
                    number = commonRow.common_number
                }
            })    
        }
        return number
    }

    return (
        <>
            <Typography sx={{ margin: '25px 0' }}>{LOCAL_CONSTANT.LABEL.COMMON_RATIO}</Typography>
            <Box sx={{ width: '100%'}}>
                <Box sx={{ display: 'flex', textAlign: 'center'}}>
                    {
                        list &&
                        Object.keys(commonRatioList).map((key) => (
                            <Box sx={{ width: '25%' }} key={key}>
                                <Typography sx={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.87)', padding: '14px 0', backgroundColor: '#00CC5B', color: 'white' }}>{common_ratio_list[key]['header']}</Typography>
                                {commonRatioList[key]['data'].map((row: any) => (
                                    <Box sx={{ display: 'flex', height: '80px', border: '1px solid rgba(0, 0, 0, 0.87)' }} key={row.key}>
                                        <Box sx={{ width: '25%', height: '100%'}}>
                                            <Typography sx={{ padding: '22px 0' }}>{displayCommonNumber(row)}</Typography>
                                        </Box>
                                        <Box sx={{ width: '25%', height: '100%' }}>
                                            <Typography sx={{ height: '50%', padding: '8px 0', borderLeft: '1px solid rgba(0, 0, 0, 0.87)', borderRight: '1px solid rgba(0, 0, 0, 0.87)' }}>{LOCAL_CONSTANT.LABEL.COMMON}</Typography>
                                            <Typography sx={{ height: '50%', padding: '8px 0', borderLeft: '1px solid rgba(0, 0, 0, 0.87)', borderRight: '1px solid rgba(0, 0, 0, 0.87)', borderTop: '1px solid rgba(0, 0, 0, 0.87)' }}>{LOCAL_CONSTANT.LABEL.UNCOMMON}</Typography>
                                        </Box>
                                        <Box sx={{ width: '25%', height: '100%' }}>
                                            <Typography sx={{ height: '50%', padding: '8px 0' }} >{showCommon(row, 'common')}</Typography>
                                            <Typography sx={{ height: '50%', padding: '8px 0', borderTop: '1px solid rgba(0, 0, 0, 0.87)' }} >{showCommon(row, 'uncommon')}</Typography>
                                        </Box>
                                        <Box sx={{ width: '25%', height: '100%' }}>
                                            <Typography sx={{ height: '100%', padding: '26px 0', borderLeft: '1px solid rgba(0, 0, 0, 0.87)' }}>{showCommon(row, 'avg')}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        ))
                    }
                </Box>
                <Box sx={{ display: 'flex', textAlign: 'center' }}>
                    <Box sx={{ width: '25%', display: 'flex' }}>
                        <Typography sx={commonRatioStyle}>{LOCAL_CONSTANT.LABEL.COMMON_RATIO}</Typography>
                        <Typography sx={commonRatioStyle}>{commonRatio('0')}</Typography>
                    </Box>
                    <Box sx={{ width: '25%', display: 'flex' }}>
                        <Typography sx={commonRatioStyle}>{LOCAL_CONSTANT.LABEL.COMMON_RATIO}</Typography>
                        <Typography sx={commonRatioStyle}>{commonRatio('1')}</Typography>
                    </Box>
                    <Box sx={{ width: '25%', display: 'flex' }}>
                        <Typography sx={commonRatioStyle}>{LOCAL_CONSTANT.LABEL.COMMON_RATIO}</Typography>
                        <Typography sx={commonRatioStyle}>{commonRatio('2')}</Typography>
                    </Box>
                    <Box sx={{ width: '25%', display: 'flex' }}>
                        <Typography sx={commonRatioStyle}>{LOCAL_CONSTANT.LABEL.COMMON_RATIO}</Typography>
                        <Typography sx={commonRatioStyle}>{commonRatio('3')}</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ImpactTable;
