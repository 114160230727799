// 項目変更履歴と評価変更履歴のテーブルのスタイル
export const tableStyle = {
  margin: '0 10px',
  width: '1200px',
  borderInline: '1px solid black',
  };
  
export const tableHeaderDateStyle = {
  width: '155px',
  minWidth: '155px',
  height: '50px',
  color: '#fff',
  border: '1px solid black',
  backgroundColor: '#00CC5B',
  padding: '4px',
};
  
export const tableHeaderNameStyle = {
  width: '125px',
  minWidth: '125px',
  height: '50px',
  color: '#fff',
  border: '1px solid black',
  backgroundColor: '#00CC5B',
  padding: '4px',
};
  
export const tableHeaderStyle = {
  width: '185px',
  height: '50px',
  color: '#fff',
  border: '1px solid black',
  backgroundColor: '#00CC5B',
  padding: '4px',
};
  
export const tableHeaderSmallStyle = {
  height: '50px',
  color: '#fff',
  border: '1px solid black',
  backgroundColor: '#00CC5B',
  padding: '4px',
};
  
export const tableCellSmallStyle = {
  border: '1px solid black',
  padding: '4px',
};

export const tableCellDateStyle = {
  width: '155px',
  minWidth: '155px',
  border: '1px solid black',
  padding: '4px',
};

export const tableCellNameStyle = {
  width: '125px',
  minWidth: '125px',
  maxWidth: '125px',
  border: '1px solid black',
  padding: '4px',
};

export const tableCellWhiteSpace = {
  width: '185px',
  minWidth: '185px',
  border: '1px solid black',
  whiteSpace: 'pre-wrap',
  padding: '4px',
};
  
export const tableCellStyle = {
  width: '185px',
  border: '1px solid black',
  whiteSpace: 'pre-wrap',
  padding: '4px',
};
  
export const tableCellStyleWhiteSpace = {
  width: '185px',
  height: '50px',
  border: '1px solid black',
  whiteSpace: 'pre-wrap',
  padding: '4px',
};