import axios from "axios";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword, User } from "firebase/auth";
import { doc, getDoc, getFirestore, onSnapshot  } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)
export const auth = getAuth(app)
export const customTokenSignIn = signInWithCustomToken
export const emailAndPasswordSignIn = signInWithEmailAndPassword
export const storage = getStorage(app, "gs://" + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET)
export const analytics = getAnalytics(app)

let initFlag = true;

// Firebase Authentication API Token取得
export const getFirebaseAuthToken = async (isRefresh: boolean = true) => {
  // ログイン認証取れるまで待つ
  const auth_flag = await isFirebaseAuthLoop()
  if (!auth_flag) {
    throw '認証エラー';
  }
  return await auth.currentUser?.getIdToken(/* forceRefresh */ isRefresh);
};

// 取得できるまでtokenを取り続ける
const isFirebaseAuthLoop = async () => {
  return new Promise<boolean>((resolve) => {
    // 0.5秒毎にトークンを取得
    const ms = 500;

    const func = (count: number) => {
      if (count > 10) {
        // 10回以上ループする場合エラーとする
        resolve(false);
      }
      if (isFirebaseAuth()) {
        resolve(true)
      } else {
        setTimeout(() => { func(count + 1) }, ms)
      }
    }

    setTimeout(() => {
      func(1);
    }, ms)
  });
}

// Firebaseユーザー情報取得
export const getFirebaseUserInfo = (): any => {
  return auth.currentUser !== null ? auth.currentUser : {};
};

// Firebaseログイン判定
export const isFirebaseAuth = (): any => {
  return auth.currentUser !== null;
};

// URLからパラメータ取得
export const getParam = (name: string, url: string): string => {
  if (!url) url = window.location.href;
  name = name.replace(/[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

// OutSystems経由ログイン
export const tokenLogin = async (url: string) => {
  // console.log("token login")
  if (!initFlag) {
    // なぜか２回動くので初回のみ動かす
    // できれば直したいが原因不明
    return "unprocessed";
  }
  initFlag = false;
  // 既に認証通っているか確認
  const authUser = await authCheck();
  if (authUser) {
    // 既に認証されている場合、そのまま返す
    const userdata = authUser as User;
    const userdoc = await getUserDocument(userdata.uid, userdata.email);
    return userdoc as string;
  }
  if (process.env.REACT_APP_MODE === "local") {
    // 開発環境の場合、回避
    console.log("ローカルの場合、直接認証")
    // throw new Error('error');
    const localUrl =
      "https://asia-northeast1-recruitviewdev.cloudfunctions.net/custom-fb-authentication-20220112";
    const result = await axios.get(localUrl);
    const localUserCredential = await customTokenSignIn(auth, result.data)
    console.log("custom");
    const userdoc = await getUserDocument(localUserCredential.user.uid, localUserCredential.user.email);
    return userdoc as string;
  } else if (process.env.REACT_APP_MODE === "dev") {
    // 開発環境の場合、回避
    console.log("devの場合")
    const devToken: string = decodeURIComponent(getParam("token", url));
    if (devToken !== process.env.REACT_APP_DEV_TOKEN) {
      // URLに設定されたtokenが一致しない場合googleに遷移させる
      window.location.href = "https://www.google.co.jp/";
    }
    // throw new Error('error');
    const localUrl =
      "https://asia-northeast1-recruitviewdev.cloudfunctions.net/custom-fb-authentication-20220112";
    const result = await axios.get(localUrl);
    const localUserCredential = await customTokenSignIn(auth, result.data)
    console.log("custom");
    const userdoc = await getUserDocument(localUserCredential.user.uid, localUserCredential.user.email);
    return userdoc as string;
  }
  // URLパラメータからfirebaseカスタムトークン（暗号）を取得
  const encryptToken: string = decodeURIComponent(getParam("token", url));
  if (encryptToken === "") {
    // トークン無しの場合、リクルートビューに遷移？？
  }
  // rview経由で受け取ったカスタムトークンを復号化する
  const params = {
    encrypt_value: encryptToken,
    "key-name": "commons-key"
  };
  const headerParam = {
    "application-name": process.env.REACT_APP_APPLICATION_NAME,
    "type-name": "login",
  }
  const commonApiUrl = process.env.REACT_APP_COMMON_API_URL! + "/outside/common-decrypt";
  // トークンを復号化
  const response = await axios.post(commonApiUrl, params, { headers: headerParam })
  const jsonResponse = JSON.parse(response.data.dataList[0]);
  const token = jsonResponse.decrypt_value;
  // 復号化したトークンでカスタムトークン認証
  const userCredential: any = await customTokenSignIn(auth, token)
  console.log("custom");
  const userdoc = await getUserDocument(userCredential.user.uid, userCredential.user.email);
  return userdoc as string;
}

const authCheck = () => {
  return new Promise(resolve => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        resolve(authUser as User)
      } else {
        resolve(null)
      }
    })
  })
}

const getUserDocument = async (uid: string, email: string | null) => {
  const user = await getDoc(doc(db, 'users', uid));
  if (user.exists()) {
    const userObject = { ...user.data(), email: email ? email : "" }
    return JSON.stringify(userObject)
  }
}

// application_info.all_users.token 取得
export const getApplicationInfoDocument = async () => {
  // リアルタイム アップデート
  const unsub = onSnapshot(doc(db, "application_info", "all_users"), (doc: any) => {
    const objectJSON = JSON.stringify(doc.data().token);
    sessionStorage.setItem('token', objectJSON);  
  });
}